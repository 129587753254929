<script>
import { globalStore } from '../main.js'
import axios from 'axios'
import DrillzLogoDark from '../assets/img/DZ_logo_black.png'
import DrillzLogoLight from '../assets/img/DZ_logo_white.png'

export default {
    components: {
    },

    data() {
        return {
            DrillzLogoDark, DrillzLogoLight,

            loading: false,
            linkedSuccessfully: false,
            successMessage: null,
            showLinkingError: false,
            linkingErrorMessage: null,
        }
    },

    computed: {
        // wallet address is what the Mandrillz backend API needs
        walletAddress() {
            return globalStore.wallet.walletAddress
        },

        // walletApi is the local wallet browser extension reference (e.g. window.cardano.eternl)
        walletApi() {
            return globalStore.wallet.ref.enableResult
        },

        // holds the profile object when logged in with Discord
        profile: {
            get: function () {
                return globalStore.account.profile
            },
            set: function (newValue) {
                globalStore.account.profile = newValue
            }
        },

        isCurrentWalletLinked() {
            return globalStore.account.profile && globalStore.account.profile.wallets && globalStore.account.profile.wallets.map(a => a.stakeAddressHash).includes(globalStore.wallet.walletAddress)
        },

        apiBaseURL() {
            return globalStore.endpoints.apiBaseURL
        },
    },

    watch: {
    },

    created() {
    },

    methods: {
        async linkWallet() {
            console.debug("ProfileView linking current wallet to profile")
            this.linkingWallet = true
            this.linkingErrorMessage = null
            this.showLinkingError = false
            this.linkedSuccessfully = false
            this.successMessage = null

            axios
                .get(this.apiBaseURL + '/account/getWalletClaimMessage/' + this.walletAddress)
                .then((response) => {
                    console.debug("ProfileView linking wallet step 1:", response)

                    return this.walletApi.signData(this.walletAddress, response.data)
                })
                .then((data) => {
                    console.debug("ProfileView linking wallet step 2:", data)

                    // we send the signed data to the Backend to 1) validate it
                    // and 2) add the wallet address to the Discord profile
                    return axios
                        .post(this.apiBaseURL + '/account/claimWallet', data)
                })
                .then((response) => {
                    console.debug("ProfileView linking wallet step 3:", response)
                    globalStore.$emit('fetchProfile')
                })
                .then((response) => {
                    console.debug("ProfileView linking wallet step 4:", response)
                    // we try to recognize that a new wallet has been added compared to previous state
                    let oldProfile = this.profile
                    let newProfile = response && response.data && response.data.id ? response.data : null
                    this.profile = newProfile

                    if (!oldProfile || !newProfile) {
                        throw new Error("profile could not be updated")
                    }

                    if ((!oldProfile.wallets && newProfile.wallets && newProfile.wallets.length > 0) ||
                        oldProfile.wallets && newProfile.wallets && oldProfile.wallets.length < newProfile.wallets.length) {
                        console.debug("ProfileView linking wallet successful")
                        this.successMessage = "Wallet linked successfully"
                        this.linkedSuccessfully = true
                    } else {
                        throw new Error("wallets could not be updated")
                    }
                })
                .catch((err) => {
                    console.error("ProfileView linking wallet error:", err)

                    this.linkingErrorMessage = err.response?.data?.message || err.message || err

                    this.linkingErrorMessage = "Not successful: " + this.linkingErrorMessage
                    this.showLinkingError = true
                })
                .finally(() => {
                    this.linkingWallet = false
                });
        },
    },
}
</script>

<template>
    <!-- LINKED WALLETS box -->
    <v-card id="profile-wallets-card" class="mt-0 mb-8 pa-5 rounded-xl" elevation="0" color="var(--color-aubergine)"
        :disabled="!profile">
        <h1 class="mb-5">Linked Wallets</h1>
        <v-alert v-if="showLinkingError" class="mt-5 rounded-lg text-wrap" type="error" color="white" text outlined
            elevation="0" dark>
            {{ linkingErrorMessage }}
        </v-alert>
        <section v-else-if="loading" class="mt-5">
            <v-progress-linear class="rounded-lg" color="var(--color-green-light)" width="100%" height="10"
                indeterminate></v-progress-linear>
        </section>
        <section v-else-if="linkedSuccessfully" class="mt-5">
            <v-alert class="rounded-lg text-wrap" type="success" elevation="0" dark>
                {{ successMessage }}
            </v-alert>
        </section>
        <h4 v-if="!profile">Login with your Discord account first</h4>
        <div v-else>
            <h3 v-if="!profile.wallets || profile.wallets.length <= 0">No wallets linked</h3>
            <v-template v-else>
                <v-row v-for="(value, key) in profile.wallets" :key="key" class="d-flex font-size-16">
                    <v-col cols="3" md="1">{{ key + 1 }}</v-col>
                    <v-col cols="9" md="3">
                        <div class="d-inline-flex justify-center align-start">
                            <v-img class="mx-1" :src="DrillzLogoLight" width="25" />
                            <div class="pl-2">{{ value.drillzToken !== null ? value.drillzToken.toLocaleString() :
                                '-' }}</div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="8">{{ value.stakeAddressHash }}</v-col>
                </v-row>
            </v-template>
        </div>
    </v-card>
</template>

<style>
</style>