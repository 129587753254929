<script>
import { globalStore } from './main.js'
import TheNavBar from './components/NavBar.vue'
import TheFooter from './components/TheFooter.vue'
import Snowflakes from 'magic-snowflakes';

import { Buffer } from 'buffer'
import axios from 'axios'
import { Address, BaseAddress } from '@emurgo/cardano-serialization-lib-browser'

export default {
  name: 'The-Mandrillz-Collectors-Portal',

  components: {
    TheNavBar,
    TheFooter
  },

  data() {
    return {
      currentView: '',
      requestedWallet: null,

      isSpecialEffect: false,

      showSplinters: true,
      endPosition: 300,
      snowflakesOptions: {
        color: '#f00', // Default: "#5ECDEF"
        // container: document.querySelector('#snowflakes-container'), // Default: document.body
        // width: 500, // Default: width of container
        // height: 250, // Default: height of container
        count: 40, // 100 snowflakes. Default: 50
        minOpacity: 0.4, // From 0 to 1. Default: 0.6
        maxOpacity: 1, // From 0 to 1. Default: 1
        minSize: 20, // Default: 10
        maxSize: 60, // Default: 25
        rotation: true, // Default: true
        speed: 2, // The property affects the speed of falling. Default: 1
        wind: true, // Without wind. Default: true
        zIndex: 100, // Default: 9999
      },
    }
  },

  methods: {
    requestWalletToConnect(chosenWallet) {
      this.requestedWallet = chosenWallet
    },

    handleSplinterRequest(endPosition) {
      this.endPosition = endPosition
      if (!this.snowflakes) {
        this.snowflakes = new Snowflakes(this.snowflakesOptions)
      }
      console.debug("Splinters requested with end position:", endPosition)
      this.snowflakes.start();
      this.snowflakes.show();
    },

    checkScrollAndRemoveSplinters() {
      if ((window.pageYOffset || document.documentElement.scrollTop) > this.endPosition) {
        this.removeSplinters()
      }
    },

    removeSplinters() {
      if (this.snowflakes) {
        this.snowflakes.hide();
        this.snowflakes.stop();
      }
    },

    handleSpecialEffect(active) {
      console.debug("App special effect:", active)
      this.isSpecialEffect = active
    },

    async fetchAdaHandle(handle) {
      if (!handle) {
        return
      }

      if (handle.startsWith("$")) {
        handle = handle.slice(1)
      } else if (handle.startsWith("%24")) {
        handle = handle.slice(3)
      }

      axios
        .get(this.walletApiURL + '/findWalletByHandle/' + handle)
        .then((response) => {
          console.debug("App fetching wallet by ADA handle result:", response)

          this.setViewerWallet(response.data)

          return response.data
        })
        .catch((err) => {
          console.debug("App fetching wallet by ADA handle error:", err)
          return null
        })
        .finally(() => {
        });
    },

    setViewerWallet(address) {
      globalStore.wallet.viewerWallet = address
      globalStore.wallet.viewerWalletLoading = false
      console.debug("App looking for viewer wallet address:", globalStore.wallet.viewerWallet)
    },

    async fetchLoginState() {
      console.debug("App fetching login state")
      globalStore.account.loading = true

      axios
        .get(this.accountApiURL + '/userInfo',
          {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
          }
        )
        .then((response) => {
          console.debug("App fetching login state response:", response)
          globalStore.account.profile = response && response.data && response.data.id ? response.data : null
        })
        .catch((err) => {
          if (err.response && err.response.status == 401) {
            console.debug("App login state: not connected to Discord")
          } else if (err.request) {
            console.error("App login state error:", err.toJSON())
          }
          globalStore.account.profile = null
        })
        .finally(() => {
          globalStore.account.loading = false
        });
    },
  },

  created() {
    this.fetchLoginState()
    globalStore.$on('fetchProfile', this.fetchLoginState)

    // add event listener that will handle requests to display a Splinter snowing effect
    globalStore.$on('requestSplinters', this.handleSplinterRequest)
    globalStore.$on('removeSplinters', this.removeSplinters)

    globalStore.$on('isSpecialEffect', this.handleSpecialEffect)

    let urlParams = new URLSearchParams(window.location.search);
    let givenViewerWalletAddress

    if (urlParams) {
      // we try to find the address type matching one of the known types
      let urlWallet = urlParams.get('wallet')?.toLowerCase()

      if (urlWallet) {
        globalStore.wallet.viewerWalletLoading = true

        if (urlWallet.startsWith("$") || urlWallet.startsWith("%24")) {
          console.debug("App transforming ADA handle to reward address:", urlWallet)

          this.fetchAdaHandle(urlWallet)
        } else if (urlWallet.startsWith("stake1")) {
          console.debug("App transforming stake address to reward address:", urlWallet)

          givenViewerWalletAddress = Buffer.from(Address.from_bech32(urlWallet).to_bytes()).toString('hex')

          this.setViewerWallet(givenViewerWalletAddress)

        } else if (urlWallet.startsWith("addr1")) {
          console.debug("App transforming wallet address to reward address:", urlWallet)

          givenViewerWalletAddress = 'e1' + Buffer.from(BaseAddress.from_address(Address.from_bech32(urlWallet)).stake_cred().to_keyhash()?.to_bytes()).toString('hex')

          this.setViewerWallet(givenViewerWalletAddress)
        }
      }
    }
  },

  beforeDestroy() {
    this.snowflakes.destroy();

    // remove event listener for Splinter snowing effect
    globalStore.$off('requestSplinters', this.handleSplinterRequest)
    globalStore.$off('removeSplinters', this.removeSplinters)

    globalStore.$off('isSpecialEffect', this.handleSpecialEffect)
  },

  watch: {
    $route(to) {
      this.currentView = to.name
    },

    profile() {
      if (!this.profile || !this.profile.wallets) {
        globalStore.account.totalDrillzBalance = null
      } else {
        globalStore.account.totalDrillzBalance = this.profile.wallets.map(a => a.drillzToken).reduce((a, b) => a + b, 0)
      }
    },
  },

  computed: {
    walletApiURL() {
      return globalStore.endpoints.apiBaseURL + '/wallet'
    },

    accountApiURL() {
      return globalStore.endpoints.apiBaseURL + '/account'
    },

    profile() {
      return globalStore.account.profile
    },

    mainAreaStyle() {
      let newStyle
      switch (this.currentView) {
        case 'home': newStyle = ''; break;
        case 'worldcup':
          switch (this.$vuetify.breakpoint.name) {
            case 'xs':
            case 'sm':
              newStyle = 'worldcup-background-small';
              break;
            case 'md':
            case 'lg':
            case 'xl':
              newStyle = 'worldcup-background';
              break;
            default:
              newStyle = 'worldcup-background-small';
          }
          break;
        default: newStyle = ''; break;
      }

      return newStyle
    },
  },
}
</script> 

<template>
  <v-app :class="mainAreaStyle" v-scroll="checkScrollAndRemoveSplinters">
    <TheNavBar :pickWalletFromOutside="requestedWallet" />
    <v-main>
      <keep-alive>
        <router-view :key="$route.fullPath"></router-view>
      </keep-alive>
    </v-main>
    <TheFooter @requestConnectWallet="requestWalletToConnect" />
    <div class="specialEffectOverlay" :class="{ startEffect: isSpecialEffect }"></div>
  </v-app>
</template>

<style scoped>
.specialEffectOverlay {
  /* visibility: hidden; */
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;

  background: linear-gradient(90deg,
        #1B3841 0%,
        #1B3841 20%,
        #089592 20%,
        #089592 40%,
        #ECB95A 40%,
        #ECB95A 60%,
        #FB9B4F 60%,
        #FB9B4F 80%,
        #F46945 80%,
        #F46945 100%);
  
    /* background-size: 100% 200%; */
    /* background-position-y: 100%; */
    /* background-position-y: 0%; */
  
    transition: top 5s ease-in-out;
}

.specialEffectOverlay.startEffect {
  /* visibility: visible; */
  /* background-position: 100% 0%; */
  top: 0px;

  transition: top 5s ease-in-out;
}

.worldcup-background {
  background-image: url('~@/assets/img/background_desktop.jpg');
  background-repeat: repeat-y;
  background-size: 100%;
  background-position-x: center;
}

.worldcup-background-small {
  background-image: url('~@/assets/img/background_mobile.jpg');
  background-repeat: repeat-y;
  background-size: 100%;
  background-position-x: center;
}
</style>


<style>
.snowflake__inner:before {
  background-image: url('~@/assets/img/splinter1.png') !important;
}

.snowflake__inner_type_1:before {
  background-image: url('~@/assets/img/splinter1.png') !important;
}

.snowflake__inner_type_2:before {
  background-image: url('~@/assets/img/splinter2.png') !important;
}

.snowflake__inner_type_3:before {
  background-image: url('~@/assets/img/splinter3.png') !important;
}

.snowflake__inner_type_4:before {
  background-image: url('~@/assets/img/splinter4.png') !important;
}
</style>