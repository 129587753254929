<script>
import { globalStore } from '../main.js'

import logo from '../assets/img/mandrillz-logo.svg'

import typhonLogo from '../assets/img/typhon-dark.svg'
import eternlLogo from '../assets/img/eternl.svg'
import namiLogo from '../assets/img/nami.svg'
import flintLogo from '../assets/img/flint.svg'
import geroLogo from '../assets/img/gero.svg'
import nufiLogo from '../assets/img/nufi-dark.svg'

import enableEternlDApp from '../assets/img/enable-eternl-dapp.png'

export default {
    data() {
        return {
            wallet: globalStore.wallet,

            logo,
            typhonLogo, eternlLogo, namiLogo, flintLogo, geroLogo, nufiLogo,
            enableEternlDApp,

            isWalletInitDone: { type: Boolean, default: false },
            isWalletEnabled: { type: Boolean, default: false },
            isTargetWalletDisabled: false,

            connectedWallet: null,

            items: {
                "typhon": { name: 'Typhon', icon: typhonLogo, walletRef: null, pluginLinks: { "chrome": "https://chrome.google.com/webstore/detail/typhon-wallet/kfdniefadaanbjodldohaedphafoffoh" }, "accountDisabledError": "no account set" },
                "eternl": { name: 'Eternl', icon: eternlLogo, walletRef: null, pluginLinks: { "chrome": "https://chrome.google.com/webstore/detail/eternl/kmhcihpebfmpgmihbkipmjlmmioameka" }, "accountDisabledError": "no account set" },
                "nami": { name: 'Nami', icon: namiLogo, walletRef: null, pluginLinks: { "chrome": "https://chrome.google.com/webstore/detail/nami/lpfcbjknijpeeillifnkikgncikgfhdo" }, "accountDisabledError": "no account set" },
                "flint": { name: 'Flint', icon: flintLogo, walletRef: null, pluginLinks: { "chrome": "https://chrome.google.com/webstore/detail/flint/hnhobjmcibchnmglfbldbfabcgaknlkj" }, "accountDisabledError": "no account set" },
                "gero": { name: 'Gero', icon: geroLogo, walletRef: null, pluginLinks: { "chrome": "https://chrome.google.com/webstore/detail/gerowallet/bgpipimickeadkjlklgciifhnalhdjhe" }, "accountDisabledError": "no account set" },
                "nufi": { name: 'Nufi', icon: nufiLogo, walletRef: null, pluginLinks: { "chrome": "https://chrome.google.com/webstore/detail/nufi/gpnihlnnodeiiaakbikldcihojploeca" }, "accountDisabledError": "no account set" },
            },

            newWalletDialog: false,
            nextTargetWallet: null,

            accountDisabledDialog: false,
        }
    },

    computed: {
        // wallet address is what the Mandrillz backend API needs
        walletAddress() {
            return globalStore.wallet.walletAddress
        },

        walletLoading() {
            return globalStore.wallet.loading
        },

        walletConnected() {
            return globalStore.wallet.connected
        },
    },
}
</script>

<template>
    <div>
        <v-app-bar class="mt-15 mb-15" elevation="0" color="transparent">
            <v-card id="the-nav-bar" attach="#the-nav-bar" class="d-flex pa-5 justify-space-between rounded-xl"
                elevation="0" color="var(--color-aubergine)" flat tile>
                <v-col class="text-left" cols="12" sm="6" md="3">
                    <v-menu class="nav-bar-menu flex-shrink-1 font-weight-bold" open-on-hover allow-overflow top
                        offset-y tile>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mr-3 rounded-lg font-weight-bold" width="200" height="60"
                                color="var(--color-primary)" v-bind="attrs" v-on="on" depressed large>
                                Navigation
                            </v-btn>
                        </template>
                        <v-card class="nav-bar-card ma-0 pa-0 rounded-lg" color="transparent" elevation="0">
                            <div class="my-2">
                                <a href="https://themandrillz.io">
                                    <v-btn class="rounded-lg font-weight-bold" height="60" depressed large>The Mandrillz<br />Homepage</v-btn>
                                </a>
                            </div>
                            <div class="my-2">
                                <router-link to="/missions">
                                    <v-btn class="rounded-lg font-weight-bold font-dark" color="var(--color-mandrillz-yellow)" light height="60" depressed large>Missions</v-btn>
                                </router-link>
                            </div>
                            <div class="my-2">
                                <router-link to="/worldcup">
                                    <v-btn class="rounded-lg font-weight-bold" height="60" depressed large>Worldcup<br />Portal</v-btn>
                                </router-link>
                            </div>
                            <div class="my-2">
                                <router-link to="/">
                                    <v-btn class="rounded-lg font-weight-bold" height="60" depressed large>My Collection</v-btn>
                                </router-link>
                            </div>
                        </v-card>
                    </v-menu><br /><br />
                    <div class="copyrightText">
                        © The Mandrillz {{ new Date().getFullYear() }}<br />
                        Made in Germany with ADA and <v-icon color="var(--color-text-gray)" small>mdi-heart</v-icon>
                    </div>
                </v-col>
                <v-col class="text-center" cols="12" sm="6" md="3">
                    <v-img :src="logo" class="mx-auto" contain />
                </v-col>
                <v-col class="text-right" cols="12" sm="6" md="3">
                    <v-menu class="nav-bar-menu flex-shrink-1" open-on-hover allow-overflow top offset-y tile>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="walletConnected && walletAddress" class="rounded-lg font-weight-bold font-light" v-bind="attrs" v-on="on" :loading="walletLoading"
                                color="var(--color-mandrillz-purple)" height="60" depressed large dark>
                                Wallet<br />connected <v-badge color="green" dot offset-x="-28" offset-y="-4"></v-badge>
                            </v-btn>
                            <v-btn v-else class="rounded-lg font-weight-bold"
                                v-bind="attrs" v-on="on" :loading="walletLoading" color="var(--vt-c-white)"
                                height="60" depressed large light>
                                Wallet not<br />connected <v-badge color="red" dot offset-x="-28" offset-y="-4">
                                </v-badge>
                            </v-btn>
                        </template>
                        <v-card class="nav-bar-card ma-0 pa-0 rounded-lg" color="transparent" elevation="0">
                            <div v-for="(item, key) in items" @click="$emit('requestConnectWallet', key)" :key="key"
                                class="my-2 justify-start">
                                <v-btn class="rounded-lg invert text-left d-flex justify-start"
                                    color="var(--color-ui-elements-dark)" dark depressed large>
                                    <div>
                                        <v-img class="mr-3" :src="item.icon" aspect-ratio="1" width="32" height="32"
                                            contain />
                                    </div>
                                    <div>{{ item.name }}</div>
                                </v-btn>
                            </div>
                        </v-card>
                    </v-menu>
                </v-col>
            </v-card>
        </v-app-bar>
        <v-dialog v-model="newWalletDialog" :fullscreen="$vuetify.breakpoint.mobile" max-width="70%">
            <v-card>
                <v-card-title class="text-h5">
                    Please install {{ nextTargetWallet?.name }} wallet
                </v-card-title>

                <v-card-text class="text-center">
                    <v-img class="mx-auto" :src="nextTargetWallet?.icon" aspect-ratio="1" width="30%" /><br />
                    You can easily use the {{ nextTargetWallet?.name }} browser wallet on the Mandrillz Portal, you just
                    need to
                    install
                    the {{ nextTargetWallet?.name }} free browser extension.<br /><br />
                    <v-btn color="primary" :href="nextTargetWallet?.pluginLinks.chrome" target="_blank" large>Install
                        browser
                        extension</v-btn>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="newWalletDialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="accountDisabledDialog" :fullscreen="$vuetify.breakpoint.mobile" max-width="70%">
            <v-card>
                <v-card-title class="text-h5">
                    Please activate DApp account
                </v-card-title>

                <v-card-text class="text-center">
                    <v-img class="mx-auto" :src="enableEternlDApp" width="80%" /><br />
                    Your DApp account seems to be disabled in the {{ nextTargetWallet?.name }} settings. Please go to
                    your
                    browser plugin's settings and select a wallet account that you want to connect to DApps and activate
                    it by
                    clicking
                    "Connect As DApp Account".<br /><br />
                    <v-btn color="primary" :href="nextTargetWallet?.pluginLinks.chrome" target="_blank" large>Install
                        browser
                        extension</v-btn>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="accountDisabledDialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped>
#the-nav-bar,
.nav-bar-card {
    font-family: 'Fira Sans';
    font-style: normal;
    font-size: 16px;
    line-height: 17px !important;
    text-decoration: none;
}

.nav-bar-menu,
.nav-bar-card,
#app>.v-menu__content {
    box-shadow: none !important;
    overflow: visible;
}

#the-nav-bar a,
.nav-bar-card a {
    text-decoration: none;
}

.nav-bar-card :not(.router-link-exact-active) button.v-btn {
    color: black;
    background: var(--vt-c-white);
    border: var(--vt-c-white);
}

.nav-bar-card :not(.router-link-exact-active) button.v-btn.invert {
    color: white;
    font-family: system-ui;
    font-size: 11px;
}

#the-nav-bar button.v-btn,
.nav-bar-card button.v-btn {
    min-width: 200px;
}

.nav-bar-card .router-link-exact-active button.v-btn {
    color: white;
    background: var(--color-mandrillz-red);
    border: var(--color-mandrillz-red);
}

.v-toolbar>>>.v-toolbar__content {
    padding: 0 !important;
}

#the-nav-bar .v-menu__content,
.nav-bar-card .v-menu__content {
    box-shadow: none;
}

#the-nav-bar #welcome-text {
    color: var(--color-beige-light) !important;
}

.copyrightText {
    color: var(--color-text-gray);
    font-family: 'Inter';
    line-height: 26px;
}
</style>