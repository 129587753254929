import Vue from 'vue'
import VueRouter from 'vue-router'
import PortalView from '../views/PortalView.vue'
import ProfileView from '../views/ProfileView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: PortalView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/missions',
    name: 'missions',
    // route level code-splitting
    // this generates a separate chunk (missions.[hash].js) for this route
    // which is lazy-loaded when the route is visited
    component: () => import(/* webpackChunkName: "missions" */ '../views/MissionsView.vue')
  },
  {
    path: '/mission/:missionId',
    name: 'mission',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (mission.[hash].js) for this route
    // which is lazy-loaded when the route is visited
    component: () => import(/* webpackChunkName: "mission" */ '../views/MissionDetailView.vue')
  },
  {
    path: '/mission/:missionId/rewards',
    name: 'missionrewards',
    props: true,
    // route level code-splitting
    // this will be together with the main mission route in a separate chunk (mission.[hash].js) for this route
    // which is lazy-loaded when the route is visited
    component: () => import(/* webpackChunkName: "mission" */ '../views/MissionRewardsView.vue')
  },
  {
    path: '/worldcup',
    name: 'worldcup',
    // route level code-splitting
    // this generates a separate chunk (worldcup.[hash].js) for this route
    // which is lazy-loaded when the route is visited
    component: () => import(/* webpackChunkName: "worldcup" */ '../views/WorldCupView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
