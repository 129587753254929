import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import LazyYoutube from "vue-lazytube";

import './assets/main.css'

Vue.config.productionTip = false

Vue.use(LazyYoutube);

// We implement a global shared data object for all global related contexts
export var globalStore = new Vue({
  data: {
    wallet: {
      loading: false,
      connected: false,
      
      name: null,
      walletAddress: null,
      balanceAddress: null,

      drillzBalance: null,
      
      ref: null,

      lastConnected: localStorage.getItem('themandrillz-last-connected-wallet'),

      viewerWallet: null,
      viewerWalletLoading: false,
    },

    account: {
      loading: false,

      totalDrillzBalance: null,
      profile: null,
    },

    mission: {
      current: null,
    },

    endpoints: {
      apiBaseURL: "/api",
    },

    policies: {
      '3f75c1332868430e28e156f6309836de8df19b620ab604c166741836': {
        host: "https://themandrillz.io/nftimgs/380px/TheMandrillz",
        ext: ".jpg",
        name: "The Mandrillz",
        placeholderData: [
          {
            "id": "1",
            "assetName": "TheMandrillz #x1",
            "assetNumber": 5888,
            "assetType": "Mandrill",
            "quantity": 1,
            "fingerprint": "QmSAV8APUHpNzS8iKWH8SnHB8yDDWr9kWpFMB5AQ23fqKk",
            "rank": 1234,
            "placeholder": true,
            "active": true,
          },
          {
            "id": "2",
            "assetName": "TheMandrillz #x2",
            "assetNumber": 6888,
            "assetType": "Mandrill",
            "quantity": 1,
            "fingerprint": "QmSAV8APUHpNzS8iKWH8SnHB8yDDWr9kWpFMB5AQ23fqKk",
            "rank": 1234,
            "placeholder": true,
            "active": true,
          },
          {
            "id": "3",
            "assetName": "TheMandrillz #x3",
            "assetNumber": 7888,
            "assetType": "Mandrill",
            "quantity": 1,
            "fingerprint": "QmSAV8APUHpNzS8iKWH8SnHB8yDDWr9kWpFMB5AQ23fqKk",
            "rank": 1234,
            "placeholder": true,
            "active": true,
          },
          {
            "id": "4",
            "assetName": "TheMandrillz #x4",
            "assetNumber": 8888,
            "assetType": "Mandrill",
            "quantity": 1,
            "fingerprint": "QmSAV8APUHpNzS8iKWH8SnHB8yDDWr9kWpFMB5AQ23fqKk",
            "rank": 1234,
            "placeholder": true,
            "active": true,
          },
        ],
      },
      'fbc12e9f94f5bb44cd7607456bf2bf3146e7c4fd6264da44b43c8cf3': {
        host: "https://themandrillz.io/gallery/companions-imgs/TheMandrillzCompanions",
        ext: ".jpg",
        name: "Companions",
      },
      // ee659e0a80e8a9815afe8aa3906741c34d234923f4da0543ec1628d4
      // fbfef0c4d74ae29cb8e2f80bb58cb2ea22d023a0f427214a9c10d0d1
      'fbfef0c4d74ae29cb8e2f80bb58cb2ea22d023a0f427214a9c10d0d1': {
        host: "https://themandrillz.io/gallery/wmdrillz-imgs/380px/TheMandrillzWorldcup",
        ext: ".jpg",
        name: "Worldcup",
        placeholderData: [
          {
            "id": "83-1",
            "assetName": "TheMandrillzWorldcup #83",
            "assetPosition": "Goalkeeper",
            "assetNumber": 83,
            "assetCopy": "#1",
            "collectionName": "Switzerland",
            "collectionIconClass": "Switzerland",
            "quantity": 1,
            "fingerprint": "asset1kz39lwxluwhu8vtxluxludgrzqdkuuqn5tqesf",
            "rank": null,
            "details": {
              "no": 1,
              "top": "none",
              "body": "yeti",
              "eyes": "god",
              "face": "whistle",
              "head": "yeti",
              "name": "TheMandrillzWorldcup #83",
              "team": "Switzerland",
              "bling": "ring silver 2earrings",
              "image": "ipfs://QmTEpRr9Fd1WQUeXCBi81MFEhpPv8bQp9sUXs2rH4vMTef",
              "artist": "@Seppow86",
              "clothes": "jersey switzerland goalkeeper",
              "glasses": "none",
              "holding": "raglove switzerland flagstsche",
              "special": "ball",
              "twitter": "https://twitter.com/TheMandrillz",
              "website": "https://themandrillz.io",
              "position": "Goalkeeper",
              "seriesno": "1",
              "occurance": "rare",
              "background": "switzerland",
              "description": "TheMandrillzWorldcup Number 83"
            },
            "placeholder": true,
            "active": true,
          },
          {
            "id": "259-1",
            "assetName": "TheMandrillzWorldcup #259",
            "assetPosition": "Left Side Midfielder",
            "assetNumber": 259,
            "assetCopy": "#1",
            "collectionName": "Tunisia",
            "collectionIconClass": "Tunisia",
            "quantity": 1,
            "fingerprint": "asset1njql9xs482sza3el55n4q9n35vnhjqqy6u73u4",
            "rank": null,
            "details": {
              "no": 7,
              "top": "none",
              "body": "universe gold",
              "eyes": "snake",
              "face": "grin rainbow",
              "head": "universe gold",
              "name": "TheMandrillzWorldcup #259",
              "team": "Tunisia",
              "bling": "ring silver",
              "image": "ipfs://QmcRHVEmoVVMCktRRAHbkSBmrCKNqKUTR16v5JBwwba7fr",
              "artist": "@Seppow86",
              "clothes": "jersey tunisia",
              "glasses": "none",
              "holding": "flags ukraine",
              "special": "none",
              "twitter": "https://twitter.com/TheMandrillz",
              "website": "https://themandrillz.io",
              "position": "Left Side Midfielder",
              "seriesno": "1",
              "occurance": "common",
              "background": "tunisia",
              "description": "TheMandrillzWorldcup Number 259"
            },
            "placeholder": true,
            "active": true,
          },
          {
            "id": "363-1",
            "assetName": "TheMandrillzWorldcup #363",
            "assetPosition": "Right Center Back",
            "assetNumber": 363,
            "assetCopy": "#1",
            "collectionName": "Netherlands",
            "collectionIconClass": "Netherlands",
            "quantity": 1,
            "fingerprint": "asset1hahdpxdv4a7t7uu2jwp7099aeykr60k45k3dry",
            "rank": null,
            "details": {
              "no": 3,
              "top": "none",
              "body": "brown",
              "eyes": "distracted",
              "face": "smile",
              "head": "brown",
              "name": "TheMandrillzWorldcup #363",
              "team": "Netherlands",
              "bling": "chain gold earrings",
              "image": "ipfs://QmWKR5d2SoQnAYPVGTNefzBkxSFXeQoH4wsfCRjwmhWjiX",
              "artist": "@Seppow86",
              "clothes": "jersey netherlands",
              "glasses": "none",
              "holding": "flags ukraine",
              "special": "none",
              "twitter": "https://twitter.com/TheMandrillz",
              "website": "https://themandrillz.io",
              "position": "Right Center Back",
              "seriesno": "1",
              "occurance": "common",
              "background": "netherlands",
              "description": "TheMandrillzWorldcup Number 363"
            },
            "placeholder": true,
            "active": true,
          },
          {
            "id": "22-1",
            "assetName": "TheMandrillzWorldcup #22",
            "assetPosition": "Offensive Midfielder",
            "assetNumber": 22,
            "assetCopy": "#1",
            "collectionName": "France",
            "collectionIconClass": "France",
            "quantity": 1,
            "fingerprint": "asset1ja7m30tfmd60rd4cmck8qk9mes5gms0v5dwx57",
            "rank": null,
            "details": {
              "no": 10,
              "top": "none",
              "body": "brown",
              "eyes": "left",
              "face": "pizza",
              "head": "brown",
              "name": "TheMandrillzWorldcup #22",
              "team": "France",
              "bling": "sub silver earrings",
              "image": "ipfs://QmSp26Fi9t7aRwNNKoSQxe3SRfUUwqRu491wBK2g9kGeqF",
              "artist": "@Seppow86",
              "clothes": "jersey france",
              "glasses": "none",
              "holding": "yellow card",
              "special": "none",
              "twitter": "https://twitter.com/TheMandrillz",
              "website": "https://themandrillz.io",
              "position": "Offensive Midfielder",
              "seriesno": "1",
              "occurance": "common",
              "background": "france",
              "description": "TheMandrillzWorldcup Number 22"
            },
            "placeholder": true,
            "active": true,
          },
        ],
      },
      '83038d08f348e25391c1fdc4860dc49f4e3d51724b051c4ef628803d': {
        // host: "https://themandrillz.io/gallery/companions-imgs/TheMandrillzCompanions",
        // ext: ".jpg",
        name: "Skullz",
      },
      'a1b073521fafe884440e2d450de459cb0d5f743aa3c3be922ea63bfc': {
        // host: "https://themandrillz.io/gallery/companions-imgs/TheMandrillzCompanions",
        // ext: ".jpg",
        name: "OG BAR",
      },
      'a2b073521fafe884440e2d450de459cb0d5f743aa3c3be922ea63bfc': {
        // host: "https://themandrillz.io/gallery/companions-imgs/TheMandrillzCompanions",
        // ext: ".jpg",
        name: "Mission Trophys",
      },
      'f3b40f56ebd4231e28c4ed85f4eb79be578daa2bc43907da85ff8448': {
        // host: "https://themandrillz.io/gallery/companions-imgs/TheMandrillzCompanions",
        // ext: ".jpg",
        name: "One Of Us",
      },
      'a63bd767a55fbaf1c961a3fd47be4a96f7275c0c9b208bcff31cb2ee': {
        // host: "https://themandrillz.io/gallery/companions-imgs/TheMandrillzCompanions",
        // ext: ".jpg",
        name: "TMZ Gallery",
      },
      'placeholder': {
        host: "https://themandrillz.io/nftimgs/380px/TheMandrillz",
        ext: ".jpg",
        name: "Sample Collection"
      },
    },
  }
});

const theMandrillzApp = new Vue({
  vuetify,
  router,
  render: h => h(App)
})
  
theMandrillzApp.$mount('#app')
