<script>
import { globalStore } from '../main.js';

export default {
    components: {
    },

    data() {
        return {
            loading: false,
        }
    },

    computed: {
        // holds the profile object when logged in with Discord
        profile() {
            return globalStore.account.profile
        },
    },

    watch: {
    },

    created() {
    },

    methods: {
    },
}
</script>

<template>
    <!-- DISCORD ROLES box -->
    <v-card id="profile-discord-card" class="mt-0 mb-8 pa-5 rounded-xl" elevation="0" color="var(--color-aubergine)"
        :disabled="!profile">
        <h1 class="mb-5">Discord Roles</h1>
        <section v-if="loading" class="my-5">
            <v-progress-linear class="rounded-lg" color="var(--color-green-light)" width="100%" height="10"
                indeterminate></v-progress-linear>
        </section>
        <h4 v-if="!profile">Login with your Discord account first</h4>
        <div v-else>
            <h3 v-if="profile && (!profile.roles || profile.roles.length <= 0)">No Discord roles</h3>
            <v-chip-group v-else class="pt-n2" column>
                <v-chip v-for="role in profile.roles" :key="role" class="mt-2 mb-0 ml-0 mr-2"
                    color="var(--color-white-1)" label light>{{ role }}</v-chip>
            </v-chip-group>
        </div>
    </v-card>
</template>