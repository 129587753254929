<script>
export default {
    data() {
        return {
            uniqueAssetsMap: {},
            uniqueTrophiesMap: {},
            uniqueSpecialsMap: {},
        }
    },

    props: {
        name: String,

        requestUrls: Array,
        requestCounter: { type: Number, default: 0 },
        clearCounter: { type: Number, default: 0 },

        collectionName: String,
        collectionIconClass: String,

        removeDuplicates: { type: Boolean, default: false },
        uniqueKey: String,
    },

    watch: {
        async requestCounter() {
            if (this.requestCounter > 0) {
                for (let url in this.requestUrls) {
                    await this.fetchCollection(this.requestUrls[url])
                }
            }
        },

        // CollectionLoader supports kind of two modes, controlled as desired by the external caller
        // 1) repeatedly loading/adding items and keeping the unique map intact, i.e. filtering items out against older loading requests
        // 2) alternatively, clearing the unique map and loading items with a fresh filter
        // Increasing this clear counter resets the unique maps and loses older loading requests/assets
        clearCounter() {
            this.uniqueAssetsMap = {}
            this.uniqueTrophiesMap = {}
            this.uniqueSpecialsMap = {}
        }
    },

    methods: {
        sanitizeClass(raw) {
            return raw ? raw.toLowerCase().replace(' ', '-') : null
        },

        async fetchCollection(requestURL) {
            if (!requestURL) {
                this.$emit('cancel', "no requestURL")
                return
            }

            this.$emit('beforeStart')

            try {
                const res = await fetch(requestURL)

                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(JSON.stringify({ message: message, status: res.status }));
                }

                const rawAssets = await res.json()
                const [newAssets, newTrophies, newSpecials] = this.parseCollection(rawAssets)

                if (newAssets && Array.isArray(newAssets)) {
                    // currently, there can't be any trophies without assets, but this could be changed in the future
                    this.$emit('complete', newAssets, newTrophies, newSpecials)
                } else {
                    this.$emit('cancel', "result is not an array")
                }
            } catch (err) {
                console.warn(this.name + " fetching collection items failed:", err.message)

                let errJson
                if ((errJson = JSON.parse(err.message))) {
                    this.$emit('error', err.message, errJson.status)
                } else {
                    console.warn(this.name + " fetching collection items failed:", err.message)
                    this.$emit('error', err)
                }
            }
        },

        filterOutDuplicates(rawAssets) {
            const newAssets = []

            if (rawAssets && Array.isArray(rawAssets)) {
                console.debug("CollectionLoader " + this.name + " filterOutDuplicates raw assets:", rawAssets)

                // we remove duplicate items from the collection based on given project-specific unique key
                rawAssets
                    .forEach(function (item) {
                        this.performDeduplication(item, newAssets, this.uniqueAssetsMap)
                    }, this);

                console.log("CollectionLoader " + this.name + " filterOutDuplicates deduplicated collection assets:", newAssets)
            }
            return newAssets
        },

        parseCollection(rawAssets) {
            const newAssets = []
            const newTrophies = []
            const newSpecials = []

            if (rawAssets && Array.isArray(rawAssets)) {
                console.debug("CollectionLoader " + this.name + " parsing raw assets:", rawAssets)

                // we remove incomplete data items from the collection and complement the data
                rawAssets
                    .filter(item => item.json)
                    .forEach(function (item, index) {
                        let details = JSON.parse(item.json)

                        if (details) {
                            if (details.team) {
                                details.team = details.team[0].toUpperCase() + details.team.slice(1)
                            }
                            if (details.no) {
                                details.no = parseInt(details.no)
                            }
                        }
                        
                        let nameSplit = !details || !details.name ? null : details.name.split('#')
                        let assetNumber = !nameSplit || nameSplit.length < 2 ? null : nameSplit[1]
                        let iconClass = !details || !details[this.collectionIconClass] ? this.collectionIconClass : details[this.collectionIconClass]

                        // 1st part: NFT name/key
                        let id = assetNumber ? assetNumber : (!details || !details.name ? index : this.sanitizeClass(details.name))
                        // 2nd part: NFT copy count
                        id += !details || !details.seriesno ? '-q-' + item.quantity : '-' + details.seriesno

                        let originalAssetName = item.assetName
                        let assetName = !details || !details.name ? item.assetName.split('#')[0] : details.name

                        let newItem = {
                            "id": id,
                            "policyId": !item.policyId ? null : item.policyId,
                            "originalAssetName": originalAssetName,
                            "assetName": assetName,
                            "assetPosition": !details || !details.position ? null : details.position,
                            "assetNumber": assetNumber ? parseInt(assetNumber) : null,
                            "assetCopy": !details || !details.seriesno ? null : '#' + details.seriesno,
                            "collectionName": !this.collectionName || !details || !details[this.collectionName] ? null : details[this.collectionName],
                            "collectionIconClass": iconClass && iconClass[0].toUpperCase() + iconClass.slice(1),
                            "quantity": item.quantity,
                            "fingerprint": item.fingerprint,
                            "rank": item.rank,
                            "details": details,
                            "placeholder": false,
                            "active": true,
                            "isTrophy": assetName && assetName.includes("Trophy"),
                            "isSpecial": this.checkIsSpecial(assetName),
                        }

                        if (newItem.isSpecial) {
                            this.performDeduplication(newItem, newSpecials, this.uniqueSpecialsMap)
                        } else if (newItem.isTrophy) {
                            this.performDeduplication(newItem, newTrophies, this.uniqueTrophiesMap)
                        } else {
                            this.performDeduplication(newItem, newAssets, this.uniqueAssetsMap)
                        }
                        
                    }, this);
            }
            return [newAssets, newTrophies, newSpecials]
        },

        checkIsSpecial(assetName) {
            return (assetName && assetName.includes("TheMandrillzWorldcup Legends"))
        },

        performDeduplication(newItem, newAssets, uniqueMap) {
            // Check if there exists an item with the unique key already (only if flag is activated) 
            let oldItem = this.removeDuplicates ? this.isItemDuplicate(newItem, uniqueMap) : null

            if (oldItem && this.uniqueKey) {
                uniqueMap[newItem[this.uniqueKey]].quantity++
            } else {
                newAssets.push(newItem)
            }
        },
        
        isItemDuplicate(item, uniqueMap) {
            if (!this.uniqueKey) {
                console.debug(this.name + " itemIsDuplicate check: no unique key provided, can't check")
                return null
            }

            if (!item
                || !Object.prototype.hasOwnProperty.call(item, this.uniqueKey)) {
                console.debug(this.name + " isItemDuplicate item does not have such a provided unique key " + this.uniqueKey + ":", item)
                return null
            }

            if (!Object.prototype.hasOwnProperty.call(uniqueMap, item[this.uniqueKey])) {
                uniqueMap[item[this.uniqueKey]] = item

                return null
            }

            // If we got that far, there exists an item already and the new item is a duplicate
            // We return the original item
            return uniqueMap[item[this.uniqueKey]]
        },
    },
}
</script>

<template>
    <div></div>
</template>