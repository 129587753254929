<script>
import CollectionGridItem from './CollectionGridItem.vue';
import CollectionLoader from './CollectionLoader.vue';

export default {
    components: {
        CollectionGridItem,
        CollectionLoader,
    },

    emits: ['beforeStart', 'cancel', 'complete', 'error', 'itemClick', 'distributionChange'],

    data() {
        return {
            collectionData: null,
            items: null,

            requestCounter: 0,
            clearCounter: 0,

            loading: false,
            showError: false,
            errorMessage: null,
            placeholderMode: false,

            distributionMap: null,

            currentWalletAddress: null,
        }
    },

    props: {
        name: { type: String, default: "DefaultGrid" },
        policyId: { type: String, required: true },
        collectionName: String,
        collectionIconClass: String,

        distributionKey: String,
        customFilter: Array,

        placeholderData: Array,

        reload: { type: Number, default: 0 },
        autoReset: { type: Boolean, default: true },
        autoSwitchSpecial: Boolean,
        maxSpecials: { type: Number, default: -1 },
        removeDuplicates: { type: Boolean, default: false },
        uniqueKey: String,

        clearAndAddItems: Array,
        addItems: Array,
        addTrophies: Array,
        addSpecials: Array,

        showTrophies: { type: Boolean, default: false },
        showSpecials: { type: Boolean, default: false },

        stylingClass: String,
        namePrefix: String,
        nameKey: String,
        tagKey: String,
        highlightKey: String,
        highlightValue: String,

        labelClassKey: String,

        collectionUrls: Array,

        customPreviewHosts: null,
        previewHostKey: null,
    },

    computed: {
        numberOfSpecials() {
            return this.items ? this.items.filter((item) => item.isSpecial == true).length : -1
        },
    },

    methods: {
        reloadFromCollectionUrls() {
            if (this.collectionUrls) {
                this.clearCounter++
                this.requestCounter++
            }
        },

        // If provided, we display some placeholder data
        // e.g. when no wallet is connected or when loaded collection is empty
        showPlaceholderData() {
            this.placeholderMode = this.placeholderData ? true : false

            if (this.placeholderData) {
                let jsonData = JSON.parse(JSON.stringify(this.placeholderData))

                console.debug("CollectionGrid " + this.name + " taking new placeholder data: ", jsonData)

                // remove all items and add placeholder data
                this.collectionData = []
                this.clearCounter++
                this.addToCollection(jsonData, null, null, false)
                
                return true
            }
            return false
        },

        onLoaderStart() {
            console.debug("CollectionGrid " + this.name + " onLoaderStart")
            this.loading = true
            this.placeholderMode = false

            this.$emit('beforeStart')
        },

        onLoaderCancel(message) {
            console.debug("CollectionGrid " + this.name + " fetching collection items cancelled for wallet address:", this.currentWalletAddress, "policyId:", this.policyId, "message:", message)
            this.loading = this.showPlaceholderData()

            this.$emit('cancel', message)
        },

        onLoaderComplete(newItems, newTrophies, newSpecials) {
            // if resetting is active: remove all old items before adding new ones
            if (this.autoReset) {
                console.debug("CollectionGrid " + this.name + " autoReset active, resetting collection data")
                this.collectionData = []
            }
            this.addToCollection(newItems, newTrophies, newSpecials, false)

            this.$emit('complete', newItems, newTrophies, newSpecials)
        },

        onLoaderError(err, status) {
            console.warn("CollectionGrid " + this.name + " fetching collection items failed:", err, "optional status:", status)
            this.loading = false

            this.errorMessage = err
            this.showError = true

            this.$emit('error', err, status)
        },

        onItemClick(asset, event) {
            console.info("Grid click:", asset, this.maxSpecials)
            if (this.autoSwitchSpecial && (this.maxSpecials < 0 || this.numberOfSpecials < this.maxSpecials)) {
                asset.isSpecial = !asset.isSpecial
            }
            this.$emit('itemClick', asset, event)
        },

        addToCollection(newItems, newTrophies, newSpecials, filterOutDuplicates) {
            if (!this.collectionData) {
                this.collectionData = []
            }

            // console.debug("CollectionGrid " + this.name + " adding items to collection with " + this.collectionData.length + " items:", newItems)

            // hereafter there exists a new array, ie. collectionData is decoupled
            // and this.items does not react on intermediate processing changes
            if (this.removeDuplicates && filterOutDuplicates) {
                // remove duplicate items (only if flag is activated)
                let uniqueItems = this.$refs.collectionLoader.filterOutDuplicates(newItems)
                this.collectionData.push(...uniqueItems)

                console.debug("CollectionGrid " + this.name + " deduplicated items to add:", uniqueItems)
            } else {
                this.collectionData.push(...newItems)
            }

            this.sortItemsByNo(this.collectionData)

            // apply possibly given filter to show/hide items
            this.filterItems(this.collectionData)

            console.debug("CollectionGrid " + this.name + " new collection looks like this:", this.collectionData)

            let freshItems = []
            freshItems.push(...this.collectionData)
            this.items = freshItems

            this.$emit('numberOfItems', this.items.length, this.policyId)
            this.$emit('change', this.items, this.policyId)
            this.$emit('trophies', newTrophies, this.policyId)
            this.$emit('specials', newSpecials, this.policyId)

            // count number of items per group/distribution key
            this.calculateDistribution()

            this.loading = false
        },

        filterItems(itemsToFilter) {
            if (!itemsToFilter || !this.customFilter)
                return

            console.debug("CollectionGrid " + this.name + " filtering items with filter:", this.customFilter)

            itemsToFilter.forEach((item) => {
                // reset to default and let the new filter rules define the result
                item.active = false

                item.active = item.active || this.customFilter.some((f) => {
                    let keys = Object.keys(f)

                    for (let key in keys) {
                        let objToTest = this.getProperty(item, keys[key])
                        
                        if (objToTest === null || objToTest != this.getProperty(f, keys[key])) {
                            return false
                        }
                    }
                    return true
                })
            })
        },

        // Search for a property on whichever nested level
        getProperty(obj, searchKey) {
            let arr = searchKey.split(".");
            let subKey
            while (arr.length) {
                subKey = arr.shift()

                if (!Object.prototype.hasOwnProperty.call(obj, subKey)) {
                    return null
                }

                obj = obj[subKey]
            }
            return obj;
        },

        calculateDistribution() {
            if (!this.distributionKey) {
                return
            }

            console.debug("CollectionGrid " + this.name + " calculating distribution with key:", this.distributionKey)

            // collection of total amounts per given distribution key, for example:
            // each object key be a team name and the value the #number of items which belongs to this team
            let newDistribution = {}

            this.items.forEach((item) => {
                let distributionValue = this.getProperty(item, this.distributionKey)

                if (item.placeholder === true || distributionValue === null) {
                    return;
                }

                if (!Object.prototype.hasOwnProperty.call(newDistribution, distributionValue)) {
                    newDistribution[distributionValue] = []
                }

                newDistribution[distributionValue].push(item)
            })

            this.distributionMap = newDistribution
        },

        sortItemsByNo(items) {
            items.sort(function (a, b) {
                // Compare two jersey numbers
                if (a.details && a.details.no && b.details && b.details.no) {
                    return a.details.no - b.details.no
                }
                else {
                    //  Compare by id as fallback
                    if (a.id < b.id) return -1;
                    if (a.id > b.id) return 1;
                    return 0;
                }
            });
        },
    },

    mounted() {
        this.reloadFromCollectionUrls()
    },

    watch: {
        reload() {
            this.reloadFromCollectionUrls()
        },

        clearCounter() {
            this.collectionData = []
            this.items = []
        },

        placeholderData() {
            this.showPlaceholderData()
        },

        clearAndAddItems() {
            console.debug("CollectionGrid " + this.name + " resetting collection data and adding items")
            this.collectionData = []

            this.addToCollection(this.clearAndAddItems, null, null, true)
        },

        addItems() {
            this.addToCollection(this.addItems, null, null, true)
        },

        addTrophies() {
            this.addToCollection(null, this.addTrophies, null, true)
        },

        addSpecials() {
            this.addToCollection(null, null, this.addSpecials, true)
        },

        customFilter() {
            this.filterItems(this.items)
        },

        distributionKey() {
            this.calculateDistribution()
        },

        distributionMap() {
            this.$emit('distributionChange', this.distributionMap)
        }
    }
}
</script>

<template>
    <div>
        <v-alert class="mb-5 font-light align-center justify-center text-wrap" type="error" :value="showError"
            border="top" colored-border rounded elevation="0">
            We're sorry, but one of the Mandrillz cracked the code. Someone from the Horde is already fixing the
            issue.<br />
            The Mandrillz's Companion took a note: {{ errorMessage ? errorMessage : '' }}
        </v-alert>
        <v-row v-if="loading" class="mb-5">
            <v-col cols="12">
                <v-progress-linear class="rounded-lg" :active="loading" color="var(--color-green-light)" indeterminate height="10"></v-progress-linear>
            </v-col>
        </v-row>
        <v-row class="d-flex">
            <v-col v-for="item in items" 
                v-show="(item.active || item.placeholder) && (showTrophies || !item.isTrophy) && (showSpecials || !item.isSpecial)" 
                class="pa-4" 
                :key="item.id" 
                cols="12"
                sm="6" md="3">
                <v-slide-y-transition>
                    <CollectionGridItem v-bind="item" 
                        :key="item.id" 
                        :class="stylingClass" 
                        :highlight-key="highlightKey" 
                        :highlight-value="highlightValue"
                        :label-class-key="labelClassKey"
                        :name-prefix="namePrefix" 
                        :name-key="nameKey"
                        :tag-key="tagKey"
                        :disabled="item.placeholder" 
                        :custom-preview-hosts="customPreviewHosts"
                        :preview-host-key="previewHostKey"
                        @itemClick="onItemClick(item, $event)">
                    </CollectionGridItem>
                </v-slide-y-transition>
            </v-col>
            <slot v-if="items && items.length"></slot>
        </v-row>
        <CollectionLoader ref="collectionLoader" 
            :name="name + '-Loader'" 
            :request-urls="collectionUrls" 
            :request-counter="requestCounter"
            :clear-counter="clearCounter"
            :collection-name="collectionName" 
            :collection-icon-class="collectionIconClass"
            :remove-duplicates="removeDuplicates" 
            :unique-key="uniqueKey"
            :special-key="highlightKey"
            :special-value="highlightValue"
            @beforeStart="onLoaderStart" 
            @cancel="onLoaderCancel" 
            @error="onLoaderError" 
            @complete="onLoaderComplete">
        </CollectionLoader>
    </div>
</template>