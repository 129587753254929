<script>
import { globalStore } from '../main.js'

import typhonLogo from '../assets/img/typhon-dark.svg'
import eternlLogo from '../assets/img/eternl.svg'
import namiLogo from '../assets/img/nami.svg'
import flintLogo from '../assets/img/flint.svg'
import geroLogo from '../assets/img/gero.svg'
import nufiLogo from '../assets/img/nufi-dark.svg'

import enableEternlDApp from '../assets/img/enable-eternl-dapp.png'

import { DAppBridge } from '../plugins/cardano-dapp-connector-bridge.min.js'

window.TheEnabledWallet = null

export default {
  data() {
    return {
      wallet: globalStore.wallet,

      typhonLogo, eternlLogo, namiLogo, flintLogo, geroLogo, nufiLogo,
      enableEternlDApp,

      isWalletInitDone: false,
      isWalletEnabled: false,
      isTargetWalletDisabled: false,

      connectedWallet: null,

      items: {
        "typhon": { name: 'Typhon', connect: 'typhoncip30', icon: typhonLogo, walletRef: null, pluginLinks: { "chrome": "https://chrome.google.com/webstore/detail/typhon-wallet/kfdniefadaanbjodldohaedphafoffoh" }, "accountDisabledError": "no account set" },
        "eternl": { name: 'Eternl', connect: 'eternl', icon: eternlLogo, walletRef: null, pluginLinks: { "chrome": "https://chrome.google.com/webstore/detail/eternl/kmhcihpebfmpgmihbkipmjlmmioameka" }, "accountDisabledError": "no account set" },
        "nami": { name: 'Nami', connect: 'nami', icon: namiLogo, walletRef: null, pluginLinks: { "chrome": "https://chrome.google.com/webstore/detail/nami/lpfcbjknijpeeillifnkikgncikgfhdo" }, "accountDisabledError": "no account set" },
        "flint": { name: 'Flint', connect: 'flint', icon: flintLogo, walletRef: null, pluginLinks: { "chrome": "https://chrome.google.com/webstore/detail/flint/hnhobjmcibchnmglfbldbfabcgaknlkj" }, "accountDisabledError": "no account set" },
        "gero": { name: 'Gero', connect: 'gero', icon: geroLogo, walletRef: null, pluginLinks: { "chrome": "https://chrome.google.com/webstore/detail/gerowallet/bgpipimickeadkjlklgciifhnalhdjhe" }, "accountDisabledError": "no account set" },
        "nufi": { name: 'Nufi', connect: 'nufi', icon: nufiLogo, walletRef: null, pluginLinks: { "chrome": "https://chrome.google.com/webstore/detail/nufi/gpnihlnnodeiiaakbikldcihojploeca" }, "accountDisabledError": "no account set" },
      },

      newWalletDialog: false,
      nextTargetWallet: null,

      accountDisabledDialog: false,
    }
  },

  props: {
    pickWalletFromOutside: String,
  },

  watch: {
    pickWalletFromOutside(walletName) {
      this.connectExternalWallet(this.items[walletName])
    },

    isWalletInitDone() {
      globalStore.wallet.loading = !this.isWalletInitDone
    },

    connectedWallet() {
      globalStore.wallet.connected = this.connectedWallet && this.isWalletEnabled
    },

    isWalletEnabled() {
      globalStore.wallet.connected = this.connectedWallet && this.isWalletEnabled
    },

    viewerWalletAddress() {
      this.isWalletInitDone = true
      globalStore.wallet.walletAddress = this.viewerWalletAddress
    },
  },

  methods: {
    // Read in and prepare displaying of supported wallets
    initWalletItems() {
      if (!window || !window.cardano)
        return

      for (let key in this.items) {
        // save reference to external (global) wallet object from Cardano variable
        this.items[key].walletRef = window.cardano[this.items[key].connect]
      }
    },

    findSomeConnectedWallet() {
      console.log("NavBar findSomeConnectedWallet last connected:", this.wallet.lastConnected)

      if (this.wallet.lastConnected) {
        let lastConnectedItem = this.items[this.wallet.lastConnected]

        if (lastConnectedItem) {
          if (lastConnectedItem.walletRef) {
            if (this.findConnectedWallet(this.wallet.lastConnected)) {
              return lastConnectedItem
            }
          } else {
            console.warn("NavBar tried connect to last connected wallet but no cardano reference exists:", this.wallet.lastConnected)
          }
        } else {
          console.warn("NavBar tried connect to unknown wallet type from last connected:", this.wallet.lastConnected)
        }
      }

      let item
      for (var key in this.items) {
        item = this.items[key]

        if (item.walletRef) {
          if (this.findConnectedWallet(key))
            return item
        }
      }
    },

    async findConnectedWallet(name) {
      if (window && window.cardano) {
        // eslint-disable-next-line
        if (window.cardano.hasOwnProperty('' + name)) {
          console.log("Found searched Cardano wallet " + name + ": ", window.cardano[name])

          let walletSeemsEnabled = await window.cardano[name].isEnabled()
          console.debug("Wallet pre-check for auto reconnecting, is enabled:", walletSeemsEnabled)

          return walletSeemsEnabled ? window.cardano[name] : null;
        }
        return null;
      }
    },

    tryReferenceNextWallet(targetWallet) {
      console.log("tryReferenceNextWallet: ", targetWallet)

      // (Again) Read in window.cardano content, might have changed while installing a new wallet plugin
      this.initWalletItems()

      if (targetWallet && targetWallet.walletRef) {
        window.TheEnabledWallet = targetWallet.walletRef

        console.debug("wallet reference:", targetWallet.walletRef);
        console.debug("apiVersion:", targetWallet.walletRef.apiVersion);
        console.debug("name:", targetWallet.walletRef.name);

        return true
      }

      return false
    },

    async connectExternalWallet(targetWallet) {
      globalStore.wallet.viewerWallet = null
      globalStore.wallet.viewerWalletLoading = false
      
      this.isWalletInitDone = false
      this.nextTargetWallet = targetWallet

      console.debug("connectExternalWallet called with:", targetWallet)

      this.referenceAndEnableWallet(targetWallet)

      this.isWalletInitDone = true
    },

    async referenceAndEnableWallet(targetWallet) {
      if (this.tryReferenceNextWallet(targetWallet)) {
        // if (targetWallet.accountDisabledError === "no account set") {
          // this.accountDisabledDialog = true
        // } else {
          /* 
          No matter if the first or subsequent time, we need to get wallet details by enabling again 
          (will not prompt any window to user if it has been done already recently) 
          */
          try {

            await this.enableWallet()

          } catch (error) {
            console.debug("Error during referenceAndEnableWallet:", error.message)
            console.debug("Check text:", targetWallet.accountDisabledError)
            if (error.message == targetWallet.accountDisabledError)
              this.accountDisabledDialog = true
          }
        // }
      } else {
        console.debug("Target wallet not known, stopping enabling")
        this.newWalletDialog = true
      }
    },

    async getIsWalletEnabled() {
      return window && window.TheEnabledWallet ? await window.TheEnabledWallet.isEnabled() : false
    },

    async enableWallet() {
      if (window && window.TheEnabledWallet) {
        try {
          window.TheEnabledWallet.enableResult = await window.TheEnabledWallet.enable()
        } catch (error) {
          console.debug("Enabling wallet cancelled:", error.message)
          throw error
        }

        await this.getBalanceAddress()
        await this.getRewardAddresses()

        this.isWalletEnabled = true
        this.connectedWallet = window.TheEnabledWallet

        console.debug("Wallet enable result:", window.TheEnabledWallet)

        // trigger global change of the shared data object
        globalStore.wallet.name = this.nextTargetWallet ? this.nextTargetWallet.name : this.connectedWallet.name
        globalStore.wallet.walletAddress = this.connectedWallet.rewardAddresses[0]
        globalStore.wallet.balanceAddress = this.connectedWallet.balanceAddress
        globalStore.wallet.ref = this.connectedWallet

        try {
          localStorage.setItem('themandrillz-last-connected-wallet', globalStore.wallet.name.toLowerCase())
        } catch (err) {
          console.warn("NavBar cannot save in local storage last connected wallet:", globalStore.wallet.name)
        }

        return window.TheEnabledWallet
      }
      return null
    },

    async getBalanceAddress() {
      if (window && window.TheEnabledWallet && window.TheEnabledWallet.enableResult) {
        window.TheEnabledWallet.balanceAddress = await window.TheEnabledWallet.enableResult.getBalance()

        return window.TheEnabledWallet.balanceAddress
      }

      return null
    },

    async getRewardAddresses() {
      if (window && window.TheEnabledWallet && window.TheEnabledWallet.enableResult) {
        window.TheEnabledWallet.rewardAddresses = await window.TheEnabledWallet.enableResult.getRewardAddresses()

        return window.TheEnabledWallet.rewardAddresses
      }

      return null
    },

    async initWalletState() {
      this.isWalletInitDone = false

      let targetWallet = this.findSomeConnectedWallet()

      if (targetWallet) {
        this.referenceAndEnableWallet(targetWallet)
      }

      this.isWalletInitDone = true
    },

    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.debug('Fallback copying wallet address was ' + msg);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy wallet address', err);
      }

      document.body.removeChild(textArea);
    },

    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(function () {
        console.debug('Copying wallet address to clipboard was successful');
      }, function (err) {
        console.error('Could not copy wallet address: ', err);
      });
    },

    copyWalletAddress() {
      console.log("Wallet to copy:", this.connectedWallet, this.wallet, this.nextTargetWallet)
      this.copyTextToClipboard(this.wallet.walletAddress)
    },
  },

  computed: {
    // wallet address is what the Mandrillz backend API needs
    viewerWalletAddress() {
      return globalStore.wallet.viewerWallet
    },

    viewerWalletLoading() {
      return globalStore.wallet.viewerWalletLoading
    }
  },
  
  async mounted() {
    DAppBridge.initCardanoDAppConnectorBridge(async () => {
      this.connectExternalWallet(this.items['eternl'])
    });

    console.debug("Cardano global var: ", window.cardano)

    this.initWalletItems()

    // if an external viewer wallet is loaded, we don't init further automatic wallet re-connection
    // and also don't stop the wallet initing yet, but rely on the watcher for viewerWalletAddress
    if (this.viewerWalletLoading) {
      return
    }

    if (this.viewerWalletAddress) {
      this.isWalletInitDone = true
      globalStore.wallet.walletAddress = this.viewerWalletAddress
    } else {
      await this.initWalletState()
    }
  },
}
</script>

<template>
  <div>
    <v-app-bar class="mt-7 px-2" elevation="0" color="transparent" :app="!$vuetify.breakpoint.mobile">
      <v-card id="the-nav-bar" attach="#the-nav-bar" class="d-flex pa-5 justify-space-between rounded-xl" elevation="0"
        color="var(--color-aubergine)" flat tile>
        <v-menu class="nav-bar-menu flex-shrink-1" open-on-hover allow-overflow bottom offset-y tile>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-3 rounded-lg" width="200" color="var(--color-primary)" height="60" v-bind="attrs" v-on="on" depressed
              large>
              Navigation
            </v-btn>
          </template>
          <v-card class="nav-bar-card ma-0 pa-0 rounded-lg" color="transparent" elevation="0">
            <div class="my-2">
              <router-link to="/">
                <v-btn class="rounded-lg" depressed large>My Collection</v-btn>
              </router-link>
            </div>
            <div class="my-2">
              <router-link to="/missions">
                <v-btn class="rounded-lg font-dark" color="var(--color-mandrillz-yellow)" light depressed large>Missions</v-btn>
              </router-link>
            </div>
            <div class="my-2">
              <router-link to="/worldcup">
                <v-btn class="rounded-lg" depressed large>Worldcup<br />Portal</v-btn>
              </router-link>
            </div>
            <div class="mt-2">
              <a href="https://themandrillz.io">
                <v-btn class="rounded-lg" depressed large>The Mandrillz<br />Homepage</v-btn>
              </a>
            </div>
          </v-card>
        </v-menu>
        <div v-if="viewerWalletAddress" class="pa-2 rounded-sm viewerModeLabel">
          <h4 class="mb-2">Viewing external wallet</h4>
          <div>{{ viewerWalletAddress }}</div>
        </div>
        <v-btn v-if="connectedWallet && isWalletEnabled" class="ml-auto py-2 mr-5 rounded-lg font-gray text-caption"
          @click="copyWalletAddress" color="white" height="60" depressed large>
          {{ wallet.name }} - {{ wallet.walletAddress.slice(0, 8) }}...
          <v-icon class="font-gray" light right>mdi-content-copy</v-icon>
        </v-btn>
        <v-menu class="nav-bar-menu flex-shrink-1" open-on-hover allow-overflow bottom offset-y tile>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!connectedWallet || !isWalletEnabled" class="rounded-lg" v-bind="attrs" v-on="on"
              :loading="!isWalletInitDone" color="var(--vt-c-white)" height="60" depressed large light>
              Wallet not<br />connected <v-badge color="red" dot offset-x="-28" offset-y="-4"></v-badge>
            </v-btn>
            <v-btn v-else class="rounded-lg font-light" v-bind="attrs" v-on="on" :loading="!isWalletInitDone"
              color="var(--color-mandrillz-purple)" height="60" depressed large dark>
              Wallet<br />connected <v-badge color="green" dot offset-x="-28" offset-y="-4"></v-badge>
            </v-btn>
          </template>
          <v-card class="nav-bar-card ma-0 pa-0 rounded-lg" color="transparent" elevation="0">
            <div v-for="(item, index) in items" @click="connectExternalWallet(item)" :key="index"
              class="my-2 justify-start">
              <v-btn class="rounded-lg invert text-left d-flex justify-start" color="var(--color-ui-elements-dark)" dark
                depressed large>
                <div>
                  <v-img class="mr-3" :src="item.icon" aspect-ratio="1" width="32" height="32" contain />
                </div>
                <div>{{ item.name }}</div>
              </v-btn>
            </div>
          </v-card>
        </v-menu>
      </v-card>
    </v-app-bar>
    <v-dialog v-model="newWalletDialog" class="rounded-lg" color="var(--color-background-light)"
      :fullscreen="$vuetify.breakpoint.mobile" max-width="70%">
      <v-card class="rounded-lg" color="var(--color-background-light)" elevation="0" light>
        <v-card-title style="width:100%;" class="text-center font-dark">
          <h1>Install the {{ nextTargetWallet?.name }} browser extension</h1>
        </v-card-title>

        <v-card-text class="text-center">
          <v-row>
            <v-col class="font-dark" cols="12" sm="6">
              <v-img class="mx-auto" :src="nextTargetWallet?.icon" aspect-ratio="1" width="30%" /><br />
              You can easily use the {{ nextTargetWallet?.name }} browser wallet on the Mandrillz Portal, you just need
              to
              install
              the {{ nextTargetWallet?.name }} free browser extension.
            </v-col>
            <v-col class="d-flex align-center justify-center align-stretch" cols="12" sm="6">
              <v-btn class="rounded-lg font-weight-bold font-light my-auto" color="var(--color-mandrillz-purple)"
                height="60" :href="nextTargetWallet?.pluginLinks.chrome" target="_blank" depressed large dark>Install
                browser
                extension</v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="rounded-lg font-weight-bold font-dark" color="var(--text-gray)" @click="newWalletDialog = false"
            height="60" text large dark>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="accountDisabledDialog" :fullscreen="$vuetify.breakpoint.mobile" max-width="70%">
      <v-card>
        <v-card-title class="text-h5">
          Please activate DApp account
        </v-card-title>

        <v-card-text class="text-center">
          <v-img class="mx-auto" :src="enableEternlDApp" width="80%" /><br />
          Your DApp account seems to be disabled in the {{ nextTargetWallet?.name }} settings. Please go to your
          browser plugin's settings and select a wallet account that you want to connect to DApps and activate it by
          clicking
          "Connect As DApp Account".<br /><br />
          <v-btn color="primary" :href="nextTargetWallet?.pluginLinks.chrome" target="_blank" large>Install browser
            extension</v-btn>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="accountDisabledDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
#the-nav-bar,
.nav-bar-card {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 17px !important;
  text-transform: uppercase;
  text-decoration: none;
}

.nav-bar-menu,
.nav-bar-card,
#app>.v-menu__content {
  box-shadow: none !important;
  overflow: visible;
}

#the-nav-bar a,
.nav-bar-card a {
  text-decoration: none;
}

.nav-bar-card :not(.router-link-exact-active) button.v-btn {
  color: black;
  background: var(--vt-c-white);
  border: var(--vt-c-white);
}

.nav-bar-card :not(.router-link-exact-active) button.v-btn.invert {
  color: white;
  font-family: system-ui;
  font-size: 11px;
}

#the-nav-bar button.v-btn,
.nav-bar-card button.v-btn {
  min-width: 200px;
}

.nav-bar-card .router-link-exact-active button.v-btn {
  color: white;
  background: var(--color-mandrillz-red);
  border: var(--color-mandrillz-red);
}

.v-toolbar>>>.v-toolbar__content {
  padding: 0 !important;
}

#the-nav-bar .v-menu__content,
.nav-bar-card .v-menu__content {
  box-shadow: none;
}

#the-nav-bar #welcome-text {
  color: var(--color-beige-light) !important;
}

#the-nav-bar .viewerModeLabel {
  background-color: var(--color-mandrillz-yellow);
    color: var(--color-text-3) !important;
}
</style>