<script>
import { globalStore } from '../main.js'
import axios from 'axios'

export default {
    data() {
        return {
            rawBalances: null,
            rawBalanceHistory: null,
            balanceHistoryValues: null,
            balanceHistoryLabels: null,

            loading: false,
            numberOfFetchedBalances: 0,
        }
    },

    props: {
        useBarChart: { type: Boolean, default: false },
        gradient: { type: Array, default: function () { return ['var(--color-accent)'] } },
        fill: { type: Boolean, default: false },
        lineWidth: { type: Number, default: 2 },
    },

    computed: {
        // wallet address is what the Mandrillz backend API needs
        walletAddress() {
            return globalStore.wallet.walletAddress
        },

        profile() {
            return globalStore.account.profile
        },

        walletAddresses() {
            return this.profile && this.profile.wallets ? this.profile.wallets.map(a => a.stakeAddressHash) : null
        },

        apiBaseURL() {
            return globalStore.endpoints.apiBaseURL
        },
    },

    watch: {
        profile() {
            // this.fetchBalanceHistoryForAll()
        },

        walletAddresses() {
            this.fetchBalanceHistoryForAll()
        },

        numberOfFetchedBalances() {
            if (!this.walletAddresses) {
                return
            }

            if (this.numberOfFetchedBalances === this.walletAddresses.length) {
                // merge all separate raw wallet balances
                this.rawBalanceHistory = [].concat(...Object.values(this.rawBalances))

                this.updateBalanceGraph()
                this.loading = false
            }
        }
    },

    mounted() {
        // this.fetchBalanceHistoryForAll()
    },

    methods: {
        fetchBalanceHistoryForAll() {
            if (!this.profile || !this.profile.wallets || !this.walletAddresses)
                return

            console.debug("TokenBalanceGraph fetching balance history for all linked wallets")
            this.loading = true
            this.rawBalances = []
            this.rawBalanceHistory = null
            this.numberOfFetchedBalances = 0

            this.walletAddresses.forEach((value) => {
                this.fetchBalanceHistory(value)
            })
        },

        fetchBalanceHistory(walletAddress) {
            if (!walletAddress)
                return

            console.debug("TokenBalanceGraph fetching balance history for address:", walletAddress)
            axios
                .get(this.apiBaseURL + '/wallet/' + walletAddress + '/drillzTokenTransactions')
                .then((response) => {
                    console.debug("TokenBalanceGraph fetch balance history result for address:", walletAddress, response)

                    this.rawBalances[walletAddress] = response.data
                })
                .catch((err) => {
                    console.debug("TokenBalanceGraph fetch balance history error for address:", walletAddress, err)

                    this.errorMessage = err.response?.data?.message || err.message || err.info || err
                    
                    this.errorMessage = "Not successful: " + this.errorMessage.replace('tx', 'transaction')
                    this.showError = true
                })
                .finally(() => {
                    // We increase also in case of an error, to complete the overall processing when all wallets are done
                    this.numberOfFetchedBalances++;
                });
        },

        updateBalanceGraph() {
            if (!this.rawBalanceHistory || !Array.isArray(this.rawBalanceHistory)) {
                console.warn("TokenBalanceGraph updating graph but balance history does not exist")
                return
            }

            let balanceHistory = {}

            this.rawBalanceHistory.forEach((value) => {
                if (!value || !value.timestamp || !value.amount) {
                    return
                }

                // we can rely on the date format and parse it simply with substring
                let moment = value.timestamp.substring(5, 7) + '/' + value.timestamp.substring(2, 4)

                if (!Object.hasOwn(balanceHistory, moment)) {
                    balanceHistory[moment] = value.amount
                } else {
                    balanceHistory[moment] += value.amount
                }
            })

            let values = Object.values(balanceHistory)
            for (let i = 1; i < values.length; i++) {
                values[i] = values[i] + values[i - 1]
            }

            this.balanceHistoryValues = values
            this.balanceHistoryLabels = Object.keys(balanceHistory)
        }
    }
}
</script>

<template>
    <v-progress-linear v-if="loading" class="rounded-lg" :active="loading" color="var(--color-green-light)" indeterminate
        height="10">
    </v-progress-linear>
    <v-sparkline v-else-if="balanceHistoryValues && balanceHistoryLabels" class="overflow-visible"
        :line-width="lineWidth" 
        :fill="fill"
        :gradient="gradient" 
        :type="useBarChart ? 'bar' : 'trend'" 
        :value="balanceHistoryValues"
        :labels="balanceHistoryLabels" 
        label-size="5"
        show-labels 
        auto-line-width 
        auto-draw
    >
    </v-sparkline>
    <span v-else>No data</span>
</template>