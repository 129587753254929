<script>
export default {
  data() {
    return {
    }
  },

  props: {
    iconClass: { type: String },
    useAsBackgroundImage: { type: Boolean, default: true },
    useAsImageSourceOnFallback: { type: Boolean, default: false },
  },
}
</script>

<template>
  <div v-if="iconClass && useAsBackgroundImage" :class="`flag-${iconClass.toLowerCase().replace(' ', '-')}`"></div>
  <img v-else-if="iconClass && useAsImageSourceOnFallback" :class="iconClass" alt="(icon)" />
</template>