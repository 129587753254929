<script>
import { globalStore } from '../main.js'
import axios from 'axios'
import DrillzLogoDark from '../assets/img/DZ_logo_black.png'
import DrillzLogoLight from '../assets/img/DZ_logo_white.png'

export default {
    name: 'ProfileView',

    components: {
    },

    data() {
        return {
            DrillzLogoDark, DrillzLogoLight,

            fetchingLoginState: true,

            successMessage: null,
            loading: false,

            linkedSuccessfully: false,
            showLinkingError: false,
            linkingErrorMessage: null,

            scrollOptions: {
                duration: 700,
                offset: 60,
                easing: 'easeInOutCubic',
            },
        }
    },

    computed: {
        // wallet address is what the Mandrillz backend API needs
        walletAddress() {
            return globalStore.wallet.walletAddress
        },

        // walletApi is the local wallet browser extension reference (e.g. window.cardano.eternl)
        walletApi() {
            return globalStore.wallet.ref.enableResult
        },

        // holds the profile object when logged in with Discord
        profile: {
            get: function () {
                return globalStore.account.profile
            },
            set: function (newValue) {
                globalStore.account.profile = newValue
            }
        },

        isCurrentWalletLinked() {
            return globalStore.account.profile && globalStore.account.profile.wallets && globalStore.account.profile.wallets.map(a => a.stakeAddressHash).includes(globalStore.wallet.walletAddress)
        },

        apiBaseURL() {
            return globalStore.endpoints.apiBaseURL
        },

        walletApiURL() {
            if (!this.walletAddress) {
                return null
            }
            return globalStore.endpoints.apiBaseURL + '/wallet/' + this.walletAddress
        },

        walletCollectionURLPrefix() {
            return globalStore.endpoints.apiBaseURL + '/wallet/'
        },
    },

    watch: {
    },

    created() {
        this.fetchLoginState()
    },

    methods: {
        fetchLoginState() {
            console.debug("ProfileView fetching login state")
            this.fetchingLoginState = true
            this.errorMessage = null
            this.showError = false

            axios
                .get(this.apiBaseURL + '/account/userInfo',
                    {
                        headers: { 'X-Requested-With': 'XMLHttpRequest' },
                    }
                )
                .then((response) => {
                    console.debug("ProfileView fetching login state response:", response)
                    this.profile = response && response.data && response.data.id ? response.data : null
                })
                .catch((err) => {
                    if (err.response && err.response.status == 401) {
                        console.debug("ProfileView login state: not connected to Discord")
                        this.profile = null
                    } else if (err.request) {
                        console.error("ProfileView login state error:", err.toJSON())

                        this.errorMessage = err.response?.data?.message || err.message || err
                        this.errorMessage = "Login check failed: " + this.errorMessage
                        this.showError = true
                    }
                })
                .finally(() => {
                    this.fetchingLoginState = false
                });
        },

        async linkWallet() {
            console.debug("ProfileView linking current wallet to profile")
            this.linkingWallet = true
            this.linkingErrorMessage = null
            this.showLinkingError = false
            this.linkedSuccessfully = false
            this.successMessage = null

            axios
                .get(this.apiBaseURL + '/account/getWalletClaimMessage/' + this.walletAddress)
                .then((response) => {
                    console.debug("ProfileView linking wallet step 1:", response)

                    return this.walletApi.signData(this.walletAddress, response.data)
                })
                .then((data) => {
                    console.debug("ProfileView linking wallet step 2:", data)

                    // we send the signed data to the Backend to 1) validate it
                    // and 2) add the wallet address to the Discord profile
                    return axios
                        .post(this.apiBaseURL + '/account/claimWallet', data)
                })
                .then((response) => {
                    console.debug("ProfileView linking wallet step 3:", response)
                    return axios
                        .get(this.apiBaseURL + '/account/userInfo',
                            {
                                headers: { 'X-Requested-With': 'XMLHttpRequest' },
                            }
                        )
                })
                .then((response) => {
                    console.debug("ProfileView linking wallet step 4:", response)
                    // we try to recognize that a new wallet has been added compared to previous state
                    let oldProfile = this.profile
                    let newProfile = this.profile = response && response.data && response.data.id ? response.data : null

                    if (!oldProfile || !newProfile) {
                        throw new Error("profile could not be updated")
                    }

                    if ((!oldProfile.wallets && newProfile.wallets && newProfile.wallets.length > 0) ||
                        oldProfile.wallets && newProfile.wallets && oldProfile.wallets.length < newProfile.wallets.length) {
                        console.debug("ProfileView linking wallet successful")
                        this.successMessage = "Wallet linked successfully"
                        this.linkedSuccessfully = true
                    } else {
                        throw new Error("wallets could not be updated")
                    }
                })
                .catch((err) => {
                    console.error("ProfileView linking wallet error:", err)

                    this.linkingErrorMessage = err.response?.data?.message || err.message || err
                    
                    this.linkingErrorMessage = "Not successful: " + this.linkingErrorMessage
                    this.showLinkingError = true
                })
                .finally(() => {
                    this.linkingWallet = false
                });
        },
    },
}
</script>

<template>
    <v-container class="mt-10 pb-md-0 px-md-0 font-size-20" width="100%" dark>
        <header class="my-8">
            <a class="text-decoration-none" href="/">
                <v-btn class="mb-8 rounded-lg font-weight-bold" name="back-to-portal-button" elevation="0"
                    height="60" color="var(--color-aubergine-3)"><v-icon left>mdi-arrow-left</v-icon> Back to Portal</v-btn>
            </a>
            
            <!-- PROFILE box -->
            <v-card class="mb-0 pa-5 rounded-t-xl rounded-b-0 " elevation="0" color="var(--color-aubergine)">
                <v-row class="d-flex">
                    <v-col class="pa-2" style="position: relative;" cols="12" md="2">
                        <v-avatar style="max-width: 100%; z-index: 2;" size="100%">
                            <v-img v-if="profile && profile.id && profile.avatar"
                                :src="`https://cdn.discordapp.com/avatars/${profile.id}/${profile.avatar}.png`"></v-img>
                            <v-img v-else :src="require('@/assets/img/avatar_placeholder.png')"></v-img>
                        </v-avatar>
                    </v-col>
                    <v-col class="pa-2" cols="12" md="7">
                        <p class="mt-3">The Mandrillz</p>
                        <h3 class="mb-5">{{ profile && profile.username ? profile.username : "Unknown Broaarr's Profile" }}</h3>
                    </v-col>
                    <v-col class="pa-2" cols="12" md="3">
                        <a v-if="!profile" class="text-decoration-none" href="/oauth2/authorization/discord">
                            <v-btn class="rounded-lg font-weight-bold" name="login-with-discord-button" elevation="0"
                                height="60" color="var(--color-mandrillz-blue)" block :loading="fetchingLoginState"
                                :disabled="fetchingLoginState">Login with Discord</v-btn>
                        </a>
                        <v-btn v-if="profile && walletAddress" :disabled="isCurrentWalletLinked"
                            class="mt-2 rounded-lg font-weight-bold" name="link-wallet-button" elevation="0" height="60"
                            color="var(--color-mandrillz-yellow)" @click="linkWallet" block light>Link Wallet</v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <v-card class="mt-lg-n13 mb-8 pa-5 rounded-t-0 rounded-b-xl" elevation="0" color="var(--color-aubergine-4)">
                <v-row class="d-flex">
                    <v-col cols="12" md="9" offset-md="2">
                        <v-btn class="font-weight-bold" name="profile-info-button" elevation="0" height="30"
                            @click="$vuetify.goTo('#profile-wallets-card', scrollOptions)" color="var(--color-text-light)"
                            text>Wallets</v-btn>
                        <v-btn class="font-weight-bold" name="profile-twitter-button" elevation="0" height="30"
                            @click="$vuetify.goTo('#profile-twitter-card', scrollOptions)"
                            color="var(--color-text-light)" text>Twitter</v-btn>
                        <v-btn class="font-weight-bold" name="profile-discord-button" elevation="0" height="30"
                            @click="$vuetify.goTo('#profile-discord-card', scrollOptions)" color="var(--color-text-light)"
                            text>Discord</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </header>

        <main class="profile-theme mt-8 mb-15">
            <!-- LINKED WALLETS box -->
            <LinkedWalletsBox />
            
            <!-- TWITTER HANDLE box -->
            <TwitterHandleBox />
            
            <!-- DISCORD ROLES box -->
            <DiscordRolesBox />
        </main>
    </v-container>
</template>

<style>
:root {
    --color-portal-card-background: #5B5B6B;
}

@media (min-width: 960px) {
    .col-md-2-5 {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.profile-theme .row {
    margin: -16px;
}

.v-application .profile-theme .primary--text {
    color: var(--color-text-light) !important;
}

.profile-theme .nye-spinner {
    animation: rotation 10s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.profile-theme .portal-grid,
.profile-theme .portal-grid .hiddenDetailsContainer {
    background-color: var(--color-aubergine);
    color: var(--color-text-light);
}

.profile-theme .portal-grid .hiddenDetailsContainer .hiddenBaseCard {
    background-color: var(--color-aubergine-mute);
}

.profile-theme .portal-grid .traitList:nth-child(odd) {
    background: var(--color-aubergine-light);
}

.profile-theme .portal-grid .infoCard,
.profile-theme .portal-grid .actionCard {
    background-color: var(--color-portal-card-background);
    color: var(--color-text-light);
}

.profile-theme .portal-grid .actionCard .actionButton {
    background-color: var(--color-text-2);
}

.profile-theme .portal-grid .assetNumberBox {
    background-color: var(--color-mandrillz-purple);
    color: white !important;
}

.horizontal-toggle-selector-active {
    background: var(--color-mandrillz-purple) !important;
}

.profile-theme .horizontal-toggle-selector-active,
.profile-theme .horizontal-toggle-selector-active * {
    color: var(--color-beige-light) !important;
}

.profile-theme .horizontal-toggle-selector-active .v-badge,
.profile-theme .horizontal-toggle-selector-active .v-badge * {
    color: var(--color-text-dark) !important;
}

.profile-theme .v-badge--icon .v-badge__badge {
    padding: 4px 0px;
}

.profile-theme .horizontalToggleButtons .v-badge *,
.profile-theme .collectionItemContainer .v-badge * {
    font-size: 16px;
    height: unset;
}

.profile-theme .collectionItemContainer.isHighlightedItem {
    background-color: var(--color-mandrillz-yellow);
    box-shadow: 0px 0px 20px 3px var(--color-mandrillz-yellow) !important;
}

.profile-theme .collectionItemContainer .sampleText {
    color: var(--color-mandrillz-yellow);
}</style>