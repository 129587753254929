<script>
import { globalStore } from '../main.js'
import axios from 'axios'

export default {
    components: {
    },

    data() {
        return {
            newTwitterHandle: null,
            savingTwitterHandle: false,
            twitterSuccessful: false,
            successMessage: null,
            showTwitterError: false,
            twitterErrorMessage: null,
        }
    },

    computed: {
        // holds the profile object when logged in with Discord
        profile() {
            return globalStore.account.profile
        },

        apiBaseURL() {
            return globalStore.endpoints.apiBaseURL
        },
    },

    watch: {
    },

    created() {
    },

    methods: {
        saveTwitterHandle() {
            console.debug("TwitterHandleBox saving Twitter handle")
            this.savingTwitterHandle = true
            this.twitterSuccessful = false
            this.twitterErrorMessage = null
            this.showTwitterError = false

            axios
                .post(this.apiBaseURL + '/account/twitterHandle',
                    {
                        twitterHandle: this.newTwitterHandle.replace(/^@/, '')
                    },
                    {
                        headers: { 'X-Requested-With': 'XMLHttpRequest' },
                    })
                .then((response) => {
                    console.debug("TwitterHandleBox saving Twitter handle response:", response)
                    this.twitterSuccessful = response && response.status === 200

                    if (this.twitterSuccessful) {
                        this.successMessage = "Twitter handle saved successfully"

                        this.profile.twitterHandle = this.newTwitterHandle.replace(/^@/, '')
                        this.newTwitterHandle = null
                    }
                })
                .catch((err) => {
                    console.error("TwitterHandleBox saving Twitter handle error:", err)

                    this.twitterErrorMessage = err.response?.data?.message || err.message || err

                    this.twitterErrorMessage = "Not successful: " + this.twitterErrorMessage
                    this.showTwitterError = true
                })
                .finally(() => {
                    this.savingTwitterHandle = false
                });
        },
    },
}
</script>

<template>
    <!-- TWITTER HANDLE box -->
    <v-card id="profile-twitter-card" class="mt-0 mb-8 pa-5 rounded-xl" elevation="0" color="var(--color-aubergine)"
        :disabled="!profile">
        <h1 class="mb-5">X Handle</h1>
        <v-alert v-if="showTwitterError" class="my-5 rounded-lg text-wrap" type="error" color="white" text outlined
            elevation="0" dark>
            {{ twitterErrorMessage }}
        </v-alert>
        <section v-else-if="savingTwitterHandle" class="my-5">
            <v-progress-linear class="rounded-lg" color="var(--color-green-light)" width="100%" height="10"
                indeterminate></v-progress-linear>
        </section>
        <section v-else-if="twitterSuccessful" class="my-5">
            <v-alert class="rounded-lg text-wrap" type="success" elevation="0" dark>
                {{ successMessage }}
            </v-alert>
        </section>
        <h4 v-if="!profile">Login with your Discord account first</h4>
        <div v-else>
            Current X handle:
            <v-chip v-if="profile && profile.twitterHandle" class="ma-2" color="cyan" label text-color="white"
                @click="newTwitterHandle = profile.twitterHandle">
                <v-icon left>mdi-twitter</v-icon>
                @{{ profile.twitterHandle }}
            </v-chip>
            <span v-else class="font-italic">not yet entered</span>
            <v-row class="mt-5 mb-0">
                <v-col class="py-0 pr-2" cols="7">
                    <v-text-field v-model="newTwitterHandle" class="rounded-lg font-dark" clearable
                        :placeholder="$vuetify.breakpoint.mobile ? 'Your X handle' : 'Enter your X handle'"
                        name="twitterHandleInput" :disabled="savingTwitterHandle"
                        background-color="var(--color-beige-light)" height="60" solo flat hide-details light>
                        <template v-slot:prepend>
                            <v-tooltip bottom dark>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" class="mt-1" dark>
                                        mdi-help-circle-outline
                                    </v-icon>
                                </template>
                                You can link this profile with your X handle and use it to participate in The
                                Mandrillz Missions with mission tweets
                            </v-tooltip>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col class="py-0" cols="5">
                    <v-btn class="rounded-lg font-weight-bold font-dark" @click="saveTwitterHandle"
                        :disabled="!newTwitterHandle || savingTwitterHandle" color="var(--color-mandrillz-yellow)"
                        height="60" block depressed large light>
                        Save
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<style></style>