<script>
import CollectionIcon from './icons/CollectionIcon.vue';

export default {
    components: {
        CollectionIcon: CollectionIcon,
    },

    emits: ['itemClick', 'imagePreviewUrl', 'imageUrl'],

    data() {
        return {
            reveal: false,
            self: this,

            defaultPreviewHost: "https://themandrillz.mypinata.cloud/ipfs/",
            defaultImageHost: "https://ipfs.io/ipfs/",
        }
    },

    props: {
        id: String,
        image: String,
        styleType: { type: String, default: 'detailed' }, // one of 'simple' or 'detailed'

        policyId: String,

        namePrefix: String,
        nameKey: String,
        tagKey: String,
        originalAssetName: String,
        assetName: String,
        assetPosition: String,
        assetNumber: Number,
        assetCopy: String,
        collectionIconClass: String,
        collectionName: String,
        details: Object,
        fingerprint: String,
        rank: Number,

        propertiesTitle: { type: String, default: 'Properties' },
        propertiesContent: String,

        quantity: Number,
        placeholder: Boolean,
        active: Boolean,

        isTrophy: Boolean,
        isSpecial: Boolean,

        highlightKey: String,
        highlightValue: String,

        labelClassKey: String,

        // if provided, items at the previewHostKey each should have minimum this structure:
        // { 'host': String } or { 'host': String, 'ext': String }
        customPreviewHosts: Object,
        previewHostKey: String,

        width: { type: String, default: "100%" },
    },

    watch: {
        isSpecial() {
            console.debug("CollectionGridItem isSpecial changed:", this.isSpecial)
        }
    },

    computed: {
        imageFingerprint() {
            return this.image ? this.image.split('/').slice(-1) : (this.details && this.details.image ? this.details.image.split('/').slice(-1) : null)
            // return this.image ? this.image.replace('ipfs://', '') : (this.details && this.details.image ? this.details.image.replace('ipfs://', '') : null)
        },

        customPreviewHost() {
            if (!this.customPreviewHosts || !this.previewHostKey) {
                return null
            }

            const hostKey = this.getProperty(this, this.previewHostKey)
            return hostKey && Object.hasOwnProperty.call(this.customPreviewHosts, hostKey)
                ? this.customPreviewHosts[hostKey]
                : null
        },

        imagePreviewUrl() {
            let newImagePreviewUrl =
                this.customPreviewHost ? this.customPreviewHost.host + this.assetNumber + (this.customPreviewHost.ext ? this.customPreviewHost.ext : '')
                :
                this.defaultPreviewHost + this.imageFingerprint

            this.$emit('imagePreviewUrl', newImagePreviewUrl)

            return newImagePreviewUrl
        },

        imageUrl() {
            let newImageUrl = this.imageFingerprint ? this.defaultImageHost + this.imageFingerprint : null
            
            this.$emit('imageUrl', newImageUrl)

            return newImageUrl
        },

        labelClass() {
            if (!this.details || !this.labelClassKey || !Object.hasOwnProperty.call(this.details, this.labelClassKey)) {
                return null
            }

            return this.sanitizeClass(this.labelClassKey) + '-' + this.sanitizeClass(this.details[this.labelClassKey])
        },
    },

    methods: {
        openBigImage() {
            if (!this.placeholder && this.imageUrl)
                window.open(this.imageUrl, '_blank').focus()
        },

        handleSelfClick() {
            console.info("GridItem click:", this)
            this.$emit('itemClick', this)
        },

        removeKeysFromDetails(keysToRemove) {
            return this.details ? Object.fromEntries(Object.entries(this.details).filter(([key]) => !keysToRemove.includes(key))) : null
        },

        getProperty(obj, searchKey) {
            let arr = searchKey.split(".");
            while (arr.length && (obj = obj[arr.shift()]));
            return obj;
        },

        sanitizeClass(raw) {
            return raw ? raw.toLowerCase().replace(' ', '-') : null
        },
    },
}
</script>

<template>
    <!-- TODO Bei Click im neuen Tab öffnen durch Gallery Plugin ersetzen -->
    <v-card @click="handleSelfClick" class="collectionItemContainer pa-0 rounded-xl" :class="{ 'isHighlightedItem': isSpecial }"
        :width="width" elevation="0" tile :id="id">
        <div :class="styleType == 'simple' ? 'pa-0' : 'pa-4'">
            <!-- ITEM IMAGE -->
            <img v-if="imagePreviewUrl" :src="imagePreviewUrl" width="100%" alt=""
                class="mainImage ma-0"  :class="styleType == 'simple' ? 'rounded-t-xl rounded-b-0' : 'rounded-lg'">
            <v-badge v-if="quantity && quantity > 1" class="quantityCounter font-weight-black font-dark"
                :content="quantity" color="var(--color-beige)" inline overlap>
            </v-badge>
            <!-- <v-badge class="quantityCounter font-weight-black font-dark"
                color="var(--color-white-1)" inline overlap>
                New Mission!
            </v-badge> -->

            <!-- NAME/ACTION area for simple view -->
            <v-card v-if="styleType == 'simple' && (namePrefix || nameKey)" class="actionCard pa-3 align-center rounded-t-0 rounded-b-xl d-flex text-left" flat>
                <div v-if="nameKey" class="assetName align-self-start font-weight-bold">
                    {{ namePrefix }}{{ getProperty(this, nameKey) }}</div>
                <div class="d-flex ml-auto">
                    <v-btn @click.prevent.stop="reveal = true" class="actionButton" elevation="0" fab small>
                        <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                </div>
            </v-card>

            <!-- NAME, NUMBER AND ID area for detailed view -->
            <v-card v-if="styleType == 'detailed' && (namePrefix || nameKey || assetPosition || assetNumber || assetCopy)" 
                class="infoCard mt-1 mb-3 pa-3 pb-7 rounded-lg d-flex text-left" tile flat>
                <v-row>
                    <!-- <v-col cols="7">
                        <div v-if="nameKey" class="assetName text-capitalize font-weight-bold">
                        {{ namePrefix }}{{ getProperty(this, nameKey) }}</div>
                        <div v-if="assetPosition" class="assetPosition text-capitalize">{{ assetPosition }}</div>
                    </v-col>
                    <v-col cols="5">
                        <div v-if="assetNumber" class="assetNumberBox rounded-sm" :class="labelClass">#{{ assetNumber }}</div>
                        <div v-if="assetCopy" class="assetCopyBox mt-3 rounded-sm">COPY {{ assetCopy }}</div>
                    </v-col> -->
                    <v-col cols="12">
                        <div v-if="tagKey || assetNumber" class="assetNumberBox rounded-sm mr-3" style="width: 80px; float: right;" :class="labelClass">
                            {{ tagKey ? getProperty(this, tagKey) : '#' + assetNumber }}
                        </div>
                        <p v-if="nameKey" class="assetName text-capitalize font-weight-bold">
                        {{ namePrefix }}{{ getProperty(this, nameKey) }}</p>
                        <div v-if="assetPosition" class="assetPosition text-capitalize">{{ assetPosition }}</div>
                        <div v-if="assetCopy" class="assetCopyBox mt-3 rounded-sm">COPY {{ assetCopy }}</div>
                    </v-col>
                </v-row>
            </v-card>

            <!-- ACTION area for detailed view -->
            <v-card v-if="styleType == 'detailed'" class="actionCard pa-3 align-center rounded-lg d-flex text-left" tile flat>
                <CollectionIcon class="collectionIcon mr-3 rounded-circle" :iconClass="collectionIconClass" />
                <div class="collectionName">
                    <span class="text-capitalize">{{ collectionName ? collectionName : "" }}</span>
                </div>
                <div class="d-flex ml-auto">
                    <v-btn @click.prevent.stop="reveal = true" class="actionButton" elevation="0" fab small>
                        <v-icon>mdi-file-document-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="imageUrl" @click.prevent.stop="openBigImage" class="ml-2 actionButton" elevation="0" fab small>
                        <v-icon>mdi-magnify-expand</v-icon>
                    </v-btn>
                </div>
                <!-- <div class="rankTitle">Rank</div> -->
                <!-- <span class="rankNumber font-weight-bold">{{ rank }}</span> -->
            </v-card>

            <!-- Diagonal SAMPLE OVERLAY -->
            <v-overlay v-if="placeholder" absolute>
                <h1 class="sampleText">SAMPLE</h1>
            </v-overlay>
        </div>

        <!-- DETAILS - additional card that is hidden by default -->
        <v-expand-transition>
            <v-card v-if="reveal" width="100%" height="100%" elevation="0" tile
                class="transition-fast-in-fast-out v-card--reveal pa-4 rounded-xl hiddenDetailsContainer">
                <v-card class="py-0 overflow-hidden rounded-md hiddenBaseCard" elevation="0" tile width="100%" height="90%">
                    <h4 class="pa-4">{{ propertiesTitle }}</h4>
                    <div v-if="propertiesContent" class="px-4 properties-content">{{ propertiesContent }}...</div>
                    <div v-else>
                        <div class="d-flex traitList px-4 justify-space-between"
                            v-for="(value, key) in removeKeysFromDetails(['name', 'image', 'files', 'Files', 'mediatype', 'mediaType', 'MediaType', 'twitter', 'Twitter', 'website', 'Website', 'description', 'seriesno'])"
                            :key="key">
                            <span class="text-capitalize">{{ key }}</span>
                            <span class="ml-auto text-capitalize">{{ value }}</span>
                        </div>
                        <div class="d-flex traitList px-4 justify-space-between"
                            v-for="(value, key) in removeKeysFromDetails(['name', 'image', 'twitter', 'Twitter', 'website', 'Website', 'description', 'seriesno'])"
                            :key="`${key}-spacer`">
                            <span class="text-capitalize">&nbsp;</span>
                            <span class="ml-auto text-capitalize">&nbsp;</span>
                        </div>
                    </div>
                </v-card>
                <v-card-actions>
                    <v-btn class="mx-auto font-dark" elevation="0" light @click.prevent.stop="reveal = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition>
    </v-card>
</template>

<style>
.collectionItemContainer .mainImage {
    position: relative;
}

.collectionItemContainer .quantityCounter {
    position: absolute;
    top: 24px;
    right: 19px;
}

.collectionItemContainer .assetName {
    font-size: 18px;
}

.collectionItemContainer .assetPosition {
    font-size: 12px;
    line-height: 21px;
    font-weight: 400;
}

.collectionItemContainer .collectionName {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;
}

.assetNumberBox,
.assetCopyBox {
    width: 100%;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.06em;
}

.hiddenDetailsContainer .traitList {
    font-size: 11px;
    line-height: 18px;
}

.hiddenDetailsContainer .properties-content {
    font-size: 16px;
}

.collectionIcon {
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
}

.collectionItemContainer .rankTitle {
    font-size: 13px;
}

.collectionItemContainer .rankNumber {
    font-size: 18px;
}

.collectionItemContainer .sampleText {
    color: var(--color-accent);

    transform: rotate(-45deg);
    /* Legacy vendor prefixes that you probably don't need... */
    /* Safari */
    -webkit-transform: rotate(-45deg);
    /* Firefox */
    -moz-transform: rotate(-45deg);
    /* IE */
    -ms-transform: rotate(-45deg);
    /* Opera */
    -o-transform: rotate(-45deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
</style>

<style scoped>
.v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
}
</style>