import { Buffer } from 'buffer'
import { Transaction, TransactionWitnessSet } from '@emurgo/cardano-serialization-lib-browser'

/* eslint-disable no-unused-vars */
export const TransactionLib = {

    // cbor: String
    // returns Transaction
    parseTransaction: function (cbor) {
        let tx = Transaction.from_bytes(Buffer.from(cbor, 'hex'))
        return tx
    },

    // witnessCbor: String
    // returns TransactionWitnessSet
    parseWitnessSet: function (witnessCbor) {
        let tws = TransactionWitnessSet.from_bytes(Buffer.from(witnessCbor, 'hex'));
        return tws
    },

    // tx: Transaction, witness: TransactionWitnessSet
    // returns Transaction
    addWitness: function (tx, witness) {
        let newTx = Transaction.new(tx.body(), witness, tx.auxiliary_data())
        return newTx
    },

    // tx: Transaction
    // returns String
    serializeTransaction: function (tx) {
        let serializedTx = Buffer.from(tx.to_bytes()).toString('hex');
        return serializedTx
    },

    // message: String
    // returns String
    hexData: function (message) {
        let hexData = Buffer.from(JSON.stringify(message), "utf8").toString('hex');
        return hexData
    }
}